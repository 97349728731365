import client  from "./BaseService";

export default {
    callBankListApi() {
        return client.apiClient.post("transaction/bank-list", {});
    },
    callNameEnquiryApi(payload) {
        return client.apiClient.post("transaction/name-enquiry", payload);
    },
    callPostSingleTransactionApi(payload){
        return client.apiClient.post("transaction/post-single-transaction", payload);
    },
    callFundTransferApi(payload){
        return client.apiClient.post("transaction/fund-transfer", payload)
    },
    callReadApi(){
        return client.apiClient.post("transaction/read", {})
    },
    callTransactionStatusQueryApi(payload){
        return client.apiClient.post("transaction/transaction-status-query",payload)
    },
};