import swal from "sweetalert";
import StoreUtils from "@/util/baseUtils/StoreUtils";
import VirtualAccountService from "@/service/VirtualAccountService";
import VirtualAccountRequest from "@/model/request/virtualAccountRequest";
import VirtualAccountResponse from "@/model/response/virtualAccountResponse";


export const state = {
    loading: false,
    virtualAccount: new VirtualAccountResponse().create,
    virtualAccounts: new VirtualAccountResponse().read,
    readPartners: new VirtualAccountResponse().readPartners,
    readByOrganisationId: new VirtualAccountResponse().readByOrganisationId,
    readById: new VirtualAccountResponse().readById,
    readByAccountNumber: new VirtualAccountResponse().readByAccountNumber,
};

export const getters = {
    getVirtualAccount: (state) => {
        return state.virtualAccount;
    },
    getVirtualAccounts: (state) => {
        return state.virtualAccounts;
    },
    getReadPartners: (state) => {
        return state.readPartners;
    },
    getReadByOrganisationId: (state) => {
        return state.readByOrganisationId;
    },
    getReadById: (state) => {
        return state.readById;
    },
    getReadByAccountNumber: (state) => {
        return state.readByAccountNumber;
    },
};


export const mutations = {
    updateLoading(state, payload){
        state.loading = payload
    },
    updateVirtualAccount(state, payload){
        state.virtualAccount = payload
    },
    updateVirtualAccounts(state, payload){
        state.virtualAccounts = payload
    },
    updateReadPartners(state, payload){
        state.readPartners = payload
    },
    updateReadByOrganisationId(state, payload){
        state.readByOrganisationId = payload
    },
    updateReadById(state, payload){
        state.readById = payload
    },
    updateReadByAccountNumber(state, payload){
        state.readByAccountNumber = payload
    },
};

export const actions = {
    // eslint-disable-next-line no-empty-pattern
    virtualAccountCreate({}, payload = new VirtualAccountRequest().create){
        StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateLoading, true)
        return VirtualAccountService.callCreateApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
                StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateVirtualAccount, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    virtualAccountUpdate({}, payload = new VirtualAccountRequest().update){
        StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateLoading, true)
        return VirtualAccountService.callUpdateApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateLoading, false)
        })
    },

    //eslint-disable-next-line no-empty-pattern
    virtualAccountRead({}, payload = {}){
        StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateLoading, true)
        return VirtualAccountService.callReadApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === '00'){
                StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateVirtualAccounts, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    virtualAccountReadPartners({}, payload = {}){
        StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateLoading, true)
        return VirtualAccountService.callReadPartnersApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === '00'){
                StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateReadPartners, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    virtualAccountReadByOrganisationId({}, payload = {}){
        StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateLoading, true)
        return VirtualAccountService.callReadByOrganisationIdApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === '00'){
                StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateReadByOrganisationId, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    virtualAccountReadById({}, payload = new VirtualAccountRequest().readById){
        StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateLoading, true)
        return VirtualAccountService.callReadByIdApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateReadById, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    virtualAccountReadByAccountNumber({}, payload = new VirtualAccountRequest().readByAccountNumber){
        StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateLoading, true)
        return VirtualAccountService.callReadByAccountNumberApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateReadByAccountNumber, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.virtualAccount.updateLoading, false)
        })
    },

};