<template>
  <div class="alpha">

    <div class="image">
      <img src="@/assets/afropaylogo.svg" alt="inter-switch-logo" >
    </div>

    <div class="text-area">
      <h3>Reset Password</h3>
    </div>

    <div class="form-alpha">
      <form @submit.prevent="initiateResetPassword">


          <input type="email" v-model="model.customerEmail"  placeholder="Enter email address"  class="input-form-1" required="required"/>



        <div class="submit">
          <base-button :loading="loading">Reset Password</base-button>
        </div>

      </form>
    </div>



  </div>
</template>

<script>
import AuthenticationRequest from "../../../model/request/AuthenticationRequest";
import StoreUtils from "../../../util/baseUtils/StoreUtils";
import BaseButton from "@/components/baseComponents/buttons/BaseButton.vue";
import {mapState} from "vuex";

export default {
  name: "ForgotPasscode",
  components: {BaseButton},
  data(){
    return {
      model: new AuthenticationRequest().initiateResetPassword,
      showPassword: false,
    }
  },
  computed:{
    ...mapState({
      loading: state => state.auth.loading,
      auth: state => state.auth,
    }),
    buttonLabel2() {
      return (this.showPassword2) ? "Hide" : "Show";
    }
  },
  methods:{
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    initiateResetPassword(){
      StoreUtils.dispatch(StoreUtils.actions.auth.initiatePasswordReset, this.model)
      StoreUtils.commit(StoreUtils.mutations.auth.updateSignInFormData, this.model)
    },
  },
}
</script>

<style scoped>
.alpha{
  margin-top: 14%;
}


.text-area h3{
  padding-bottom: 0;
  margin-bottom: 0;
  text-align: center;
  letter-spacing: 0.5px;
  color: #101828;
  font-weight: 600;
  font-size: 21px;
  line-height: 30px;
}

.text-area p{
  padding-top: 2px;
  margin-top: 2px;
  text-align: center;
  letter-spacing: 0.5px;
}

.text-area{
  margin-bottom: 0.5%;
}

.form-alpha{
  margin-left: 38%;
  margin-right: 38%;
}

.image{
  display: flex;
  justify-content: center;
  margin-bottom: 0.8%;
}




.input-form-1{
  order: 1;
  width: 100%;
  padding: 12px 20px;
  margin: 15px 0;
}


input {
  box-sizing: border-box;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  -webkit-transition: 0.3s;
  padding-top: 12px;
  padding-bottom: 12px;
  transition: 0.3s;
  outline: none;
  color: #667085;
  letter-spacing: 0.5px;
}

input:focus {
  border: 1px solid #24405A;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

button{
  background-color: transparent;
}


.submit{
  margin-top: 2%;
}




</style>