<template>
  <div>
    <dashboard-layout>
      <dash-board-settings-user-view/>
    </dashboard-layout>
  </div>
</template>

<script>

import DashBoardSettingsUserView from "@/components/dashboard/DashBoardSettingsUserView.vue";
import DashboardLayout from "@/layout/DashboardLayout.vue";

export default {
  name: "SettingsUser",
  components: {DashboardLayout, DashBoardSettingsUserView},
}
</script>

<style scoped>

</style>