class WalletResponse {

    create = {
        customerId: null,
        accountNumber: null,
        responseCode: null,
        responseMessage: null
    };
    balanceEnquiry = {
        accountBalance: null,
        accountAvailableBalance: null,
        accountTotalCredit: null,
        accountTotalDebit: null,
        accountLedgerBalance: null,
        accountBankCode: null,
        accountBankName: null,
        accountBranchCode: null,
        accountCurrency: null,
        accountCustomerPhone: null,
        accountName: null,
        accountNumber: null,
        responseCode: null,
        responseMessage: null
    };

    retrieve = {
        accountId: null,
        accountCustomerId: null,
        accountNumber: null,
        accountName: null,
        accountCurrency: null,
        accountBalance: null,
        accountStatus: null,
        accountLedgerBalance: null,
        accountPhone: null,
        accountEmail: null,
        accountBvn: null,
        accountAllowCredit: null,
        accountAllowDebit: null,
        accountMonthlyDebit: null,
        accountMonthlyCredit: null,
        accountDailyDebit: null,
        accountDailyCredit: null,
        accountAvailableBalance: null,
        accountMaximumBalance: null,
        accountMinimumBalance: null,
        responseCode: null,
        responseMessage: null
    };

    read = {
        responseCode: null,
        responseMessage: null,
        data: []
    };

    readLien = {
        responseCode: null,
        responseMessage: null,
        dataReadLien: []
    };

    readWalletActivityByAccountNumber = {
        responseCode: null,
        responseMessage: null,
        dataReadWalletActivityByAccountNumber: []
    };

    readWalletActivity = {
        responseCode: null,
        responseMessage: null,
        accounts: [],
        dataReadWalletActivity: []
    };

    data = {
        accountId: null,
        accountCustomerId: null,
        accountNumber: null,
        accountName: null,
        accountCurrency: null,
        accountBalance: null,
        accountStatus: null,
        accountLedgerBalance: null,
        accountAllowCredit: null,
        accountAllowDebit: null,
        accountMonthlyDebit: null,
        accountMonthlyCredit: null,
        accountDailyDebit: null,
        accountDailyCredit: null,
        accountAvailableBalance: null,
        accountMaximumBalance: null,
        accountMinimumBalance: null
    }

    dataReadLien = {
        lienAmountId: null,
        lienAmountReference: null,
        lienAmountBlockAmount: null,
        lienAmountUnblockAmount: null,
        lienAmountAccount: null,
        lienAmountBankCode: null,
        lienAmountStatus: null,
        lienAmountUpdatedAt: null,
        lienAmountCreatedAt: null
    }

    dataReadWalletActivityByAccountNumber = {}

    dataReadWalletActivity = {}

    accounts = {
        accountAllowCredit: null,
        accountAllowDebit: null,
        accountAllowNegativeBalance: null,
        accountAmountToSave: null,
        accountAutoSave: null,
        accountAvailableBalance: null,
        accountBalance: null,
        accountBalanceControl: null,
        accountBankCode: null,
        accountBranchCode: null,
        accountBvn: null,
        accountChecker: null,
        accountClass: null,
        accountCreatedDate: null,
        accountCurrency: null,
        accountCustomerId: null,
        accountDailyCredit: null,
        accountDailyDebit: null,
        accountDateOpened: null,
        accountDebitStatus: null,
        accountEmail: null,
        accountEndDate: null,
        accountGlCategory: null,
        accountGlLevel1Code: null,
        accountGlLevel2Code: null,
        accountId: null,
        accountInterest: null,
        accountLastAccessTimestamp: null,
        accountLedgerBalance: null,
        accountLienAmount: null,
        accountMaker: null,
        accountMaximumBalance: null,
        accountMinimumBalance: null,
        accountMonthlyCredit: null,
        accountMonthlyDebit: null,
        accountName: null,
        accountNumber: null,
        accountOverdraft: null,
        accountOverdraftRate: null,
        accountOverdraftStatus: null,
        accountPenalty: null,
        accountPhone: null,
        accountRate: null,
        accountStartDate: null,
        accountStatus: null,
        accountTotalCredit: null,
        accountTotalDebit: null,
        accountType: null,
        accountUpdatedDate: null,
        page: null
    }

}

export default WalletResponse;