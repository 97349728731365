class ApiKeyRequest {

    readApiKeyByOrganisationId = {
        apikeyOrganisationId: null,
    };

    readApiKeyById = {
        apikeyOrganisationId: null,
        apikeyId: null,
    };

    updateApiKey = {
        apikeyId: null,
        apikeyOrganisationId: null,
    };

    generateApiKey = {
        organisationId: null,
    };

    updateWebhookAndCallback = {
        apikeyId: null,
        apikeyOrganisationId: null,
        apikeyTestWebhook: null,
        apikeyTestCallback: null,
        apikeyLiveWebhook: null,
        apikeyLiveCallback: null,
        apikeyTestHeaderKey: null,
        apikeyTestHeaderValue: null,
        apikeyLiveHeaderKey: null,
        apikeyLiveHeaderValue: null,
    };
}

export default ApiKeyRequest;