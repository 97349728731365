<template>
  <set-new-passcode/>
</template>

<script>
import SetNewPasscode from "../../components/auth/signIn/SetNewPasscode";
export default {
  name: "SetNewPassword",
  components: {SetNewPasscode}
}
</script>

<style scoped>

</style>