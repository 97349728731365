import { render, staticRenderFns } from "./DashBoardTransactionsView.vue?vue&type=template&id=dd1e9260&scoped=true&"
import script from "./DashBoardTransactionsView.vue?vue&type=script&lang=js&"
export * from "./DashBoardTransactionsView.vue?vue&type=script&lang=js&"
import style0 from "./DashBoardTransactionsView.vue?vue&type=style&index=0&id=dd1e9260&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd1e9260",
  null
  
)

export default component.exports