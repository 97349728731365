<template>
  <div>
    <create-account-verify-email-address/>
  </div>
</template>

<script>
import CreateAccountVerifyEmailAddress from "../../components/auth/signUp/CreateAccountVerifyEmailAddress";
export default {
  name: "VerifyEmailAddress",
  components: {CreateAccountVerifyEmailAddress}
}
</script>

<style scoped>

</style>