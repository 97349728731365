<template>
  <div>
    <created-successfully/>
  </div>
</template>

<script>
import CreatedSuccessfully from "../../components/auth/signUp/CreatedSuccessfully";
export default {
  name: "AccountCreatedSuccessfully",
  components: {CreatedSuccessfully}
}
</script>

<style scoped>

</style>