<template>
  <div>
    <passcode-reset-successful/>
  </div>
</template>

<script>
import PasscodeResetSuccessful from "../../components/auth/signIn/PasscodeResetSuccessful";
export default {
  name: "PasswordResetSuccessful",
  components: {PasscodeResetSuccessful}
}
</script>

<style scoped>

</style>