<template>
  <section class="home-section">
    <div class="home-content">
      <div class="section-1">
        <img src="@/assets/Notification.svg" alt="Notification">
        <div class="vl"></div>
        <img src="@/assets/Avatar.svg" alt="avatar-dash">
        <p class="profile-name">{{this.userInfo.customerFirstName}} {{this.userInfo.customerLastName}}</p>
      </div>
      <slot>
      </slot>
    </div>
  </section>
</template>

<script>
import {mapState} from "vuex";


export default {
  name: "DashContent",
  computed:{
    ...mapState({
      loading: state => state.auth.loading,
      auth: state => state.auth,
      userInfo: state => state.auth.userInfo,
    }),
  },
}
</script>

<style scoped>
.home-content{
  position: fixed;
  left: 225px;
  background-color: #F9FAFB;
  width: calc(100% - 215px);
  height: 100vh;
  transition: all 0.5s ease;
  /*color: white;*/
  margin: 0 10px;
  cursor: pointer;
  overflow-y: scroll;
}
.section-1{
  display: flex;
  justify-content: right;
  align-content: center;
  align-items: center;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  padding-right: 3%;
  background-color: #FFFFFF;
}
.vl{
  border-left: 1.5px solid #E7E8F0;
  height: 35px;
  margin-left: 1%;
  margin-right: 1%;
}
.profile-name{
  padding-left: 1%;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #58596C;
  text-transform: lowercase;
}


.profile-name::first-letter {

  text-transform: capitalize;

}

@media (max-width: 990px) {
  .home-content{
    position: fixed;
    left: unset;
    width: 100%;
    height: 100%;
    margin: unset;
    /*padding: 10px;*/
    padding-left: 12px;
    padding-right: 20px;
    overflow-y: scroll;
  }

}

</style>

