import { render, staticRenderFns } from "./ForgotPasscode.vue?vue&type=template&id=5d57a072&scoped=true&"
import script from "./ForgotPasscode.vue?vue&type=script&lang=js&"
export * from "./ForgotPasscode.vue?vue&type=script&lang=js&"
import style0 from "./ForgotPasscode.vue?vue&type=style&index=0&id=5d57a072&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d57a072",
  null
  
)

export default component.exports