<template>
  <div class="page-wrapper" v-if="userInfo.responseCode === '00'">
    <slot></slot>
  </div>
</template>

<script>
import StoreUtils from "@/util/baseUtils/StoreUtils";
import RouterUtils from "@/util/baseUtils/RouterUtils";
import {mapState} from "vuex";

export default {
name: "DashboardLayout",
  ...mapState({
    loading: state => state.auth.loading,
    auth: state => state.auth,
    userInfo: state => state.auth.userInfo,
  }),
  computed:{
    userInfo(){
      return StoreUtils.rootGetters(StoreUtils.getters.auth.getUserInfo)
    },
  },
  mounted() {
    if(localStorage.token){
      StoreUtils.dispatch(StoreUtils.actions.auth.getUserDetails)
    }else {
      RouterUtils.navigateTo(RouterUtils.routes.auth.logInWithEmail.name)
    }
  },
}
</script>

<style scoped>

</style>