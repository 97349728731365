<template>
<div class="alpha">

 <dash-board-side-menu/>

<dash-content>
  <div class="body">
    <h2>Wallet Activities</h2>
    <div class="row trans-mgt">
      <div class="form-group fg--search">
        <button type="submit"><i class="fa fa-search"></i></button>
        <input type="text" class="input" placeholder="Search transaction..."/>
      </div>
      <div class="row filter_group">
        <div class="row white">
          <img src="@/assets/Filterslines.svg"  alt="Export"/>
          Filter
        </div>
        <div class="blue" @click="convertToPDF">Download transactions</div>
      </div>
    </div>
    <div class="section-5">
      <div class="empty-state"  v-if="this.readWalletActivity.data.length === 0">
      <img src="@/assets/empty.svg" alt="empty">
      <p class="empty-state-text-1">You have nothing to see</p>
      <p class="empty-state-text-2">This is where your Wallet Activities will appear</p>
      <p class="empty-state-text-3">
          <i class='bx bx-plus' ></i>
          Transaction
      </p>
      </div>

      <div class="table" v-if="this.readWalletActivity.data.length >0">

          <table ref="pdfContent">
          <tr>
              <th>Sender</th>
              <th>Receiver</th>
              <th>Amount</th>
              <th>Transaction Reference</th>
              <th>Payment Reference</th>
              <th>Date</th>
              <th></th>
          </tr>

          <tbody v-for="child in paginatedItems" :key="child.key">
          <tr>
            <td>{{child.accountName}}</td>
            <td>{{child.counterPartyAccountName}}</td>
            <td>{{child.amount}}</td>
            <td>{{child.paymentReference | formatTextWithEllipsis}}</td>
            <td>{{child.contractReference | formatTextWithEllipsis}}</td>
            <td>{{child.transactionDate | formatTextWithEllipsis}}</td>
            <td>
              <div class="separate-1">
                <p>
                  <i class='bx bx-log-in-circle' v-if="child.drCr === 'CR'"></i>
                  <i class='bx bx-log-out-circle' v-if="child.drCr === 'DR'" ></i>
                </p>
              </div>
            </td>
          </tr>
          </tbody>

          </table>

        <div class="pagination">
          <button @click="previousPage" :disabled="currentPage === 1" class="previous">Previous</button>
          <div class="page-indicator">
            Page {{ currentPage }} of {{ totalPages }}
          </div>
          <button @click="nextPage" :disabled="currentPage === totalPages" class="previous">Next</button>
        </div>
          </div>
    </div>

  </div>

</dash-content>
</div>
</template>

<script>
import DashBoardSideMenu from "@/components/dashboard/DashBoardSideMenu";
import DashContent from "@/components/baseComponents/dash/DashContent";
import {mapState} from "vuex";
import StoreUtils from "@/util/baseUtils/StoreUtils";
import html2pdf from 'html2pdf.js';
export default {
    name:"DashBoardWalletActivitiesView",
    components: {DashContent, DashBoardSideMenu,},
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 9,
    };
  },
  computed:{
    ...mapState({
      loading: state => state.fundTransfer.loading,
      auth: state => state.auth,
      userInfo: state => state.auth.userInfo,
      readWalletActivity: state => state.wallet.readWalletActivity,
    }),
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.readWalletActivity.data.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.readWalletActivity.data.length / this.itemsPerPage);
    },
  },
  methods: {
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },

    goToPage(pageNumber) {
      if (pageNumber > 0 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
      }
    },

    convertToPDF() {
      const element = this.$refs.pdfContent;
      const opt = {
        margin:       0,
        filename:     'generated.pdf',
        image:        { type: 'jpeg', quality: 0.98 },
        html2canvas:  { scale: 2 },
        jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };
      // Create the PDF
      html2pdf().from(element).set(opt).save();
    },

  },
  async mounted() {
    await StoreUtils.dispatch(StoreUtils.actions.wallet.walletReadWalletActivity,)
  },
}
</script>
<style scoped>
*{
  padding:0;
  margin:0;
}
.alpha{
  display: flex;
}
.body{
  padding: 32px;
}
h2{
  font-weight: 700;
  font-size: 19px;
  line-height: 25px;
  color: #353542;
}
.row{
  display: flex;
}
.trans-mgt{
  margin-top: 17px;
  /* align-items: center; */
}
.filter_group{
  margin-left: auto;
  gap: 16px;
}

.white{
  display: flex;
  align-items: center;
  align-content: center;
  background-color: #FFFFFF;
  border: 1px solid #D0D5DD;
  font-size: 13px;
  padding:  6px 14px;
  border-radius: 5px;
  gap: 10px;
}
.blue{
  display: flex;
  align-items: center;
  align-content: center;
  padding: 6px 14px;
  border-radius: 5px;
  font-size: 13px;
  background-color: #1570EF;
  border: 1px solid #1570EF;
  color: #ffffff;
}

.fg--search {
  background: white;
  position: relative;
  width: 400px;
  margin-left: 1%;
}
.fg--search input {
  width: 100%;
  padding: 10px;
  padding-left: 15px;
  display: block;
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
}

.fg--search button {
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  margin-top: 5px;
}

.fa-search{
  color: #667085;
  margin-right: 10px;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 3%;
}
.table{
  margin-left: 1%;
  margin-right: 1%;
}

tr{
  border: 1px solid #E3EBF6;
}

th {
  background-color: #F9FBFD;
  padding: 10px;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 14px;
  color: #667085;
  text-align: center;
}

td {
  /*border: 1px solid #E3EBF6;*/
  text-align: center;
  align-items: center;
  align-content: center;
  padding: 15px 8px;
  /*letter-spacing: 1px;*/
  color: #667085;
  font-weight: 200;
  font-size: 15px;
  /*border-bottom: 1px solid #E3EBF6;*/
  text-transform: lowercase;
}
td::first-letter {
  text-transform: capitalize;
}
.empty-state{
  text-align: center;
  margin-top: 7%;
  margin-right: 8%;
}
.empty-state-text-1{
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #353542;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}
.empty-state-text-2{
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #353542;
  padding-bottom: 1.25%;
}
.empty-state-text-3{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;
  width: 130px;
  margin-right: auto;
  margin-left: auto;
  height: 35px;
  background: #0765FF;
  color: #FFFFFF;
  border: 1px solid #0765FF;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 5px;
  font-size: 13px;
}
.empty-state-text-3:hover{
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.pagination{
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}

.previous{
  /*display: flex;*/
  /*align-content: center;*/
  /*align-items: center;*/
  text-align: center;
  padding: 8px 14px;
  gap: 8px;
  font-size: 12px;
  width: 120px;
  height: 32px;
  background: #1570EF;
  color: #ffffff;
  border: 1px solid #1570EF;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
}

.previous:hover{
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.page-indicator{
  color: #667085;
  font-weight: 200;
  font-size: 15px;
}

.bx-log-in-circle{
  color: #0aae43;
  font-size: 23px;
  font-weight: 300;
}

.bx-log-out-circle{
  color: #DB657B;
  font-size: 23px;
  font-weight: 300;
}
</style>