import client  from "./BaseService";

export default {
    callReadApiKeyApi() {
        return client.apiClient.post("api-key/read", {});
    },
    callReadApiKeyByOrganisationIdApi(payload) {
        return client.apiClient.post("api-key/read-by-organisation-id", payload);
    },
    callReadApiKeyByIdApi(payload) {
        return client.apiClient.post("api-key/read-by-id", payload);
    },
    callUpdateApiKeyApi(payload) {
        return client.apiClient.post("api-key/update", payload);
    },
    callGenerateApiKeyApi(payload) {
        return client.apiClient.post("api-key/create", payload);
    },
    callUpdateWebhookAndCallbackApi(payload) {
        return client.apiClient.post("api-key/update-webhook-callback", payload);
    },
};