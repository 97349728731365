import RouterUtils from "../../util/baseUtils/RouterUtils";
import SignUpEmail from "../../views/Auth/SignUpEmail";
import VerifyEmailAddress from "../../views/Auth/VerifyEmailAddress";
import AccountCreatedSuccessfully from "../../views/Auth/AccountCreatedSuccessfully";
import LoginWithEmail from "../../views/Auth/LoginWithEmail";
import LoginSuccess from "../../views/Auth/LoginSuccess";
import ForgotPassword from "../../views/Auth/ForgotPassword";
import ForgotPasswordVerifyEmail from "../../views/Auth/ForgotPasswordVerifyEmail";
import SetNewPassword from "../../views/Auth/SetNewPassword";
import PasswordResetSuccessful from "../../views/Auth/PasswordResetSuccessful";
import SignUpSetPassword from "../../views/Auth/SignUpSetPassword";


export default [

    {
        path: RouterUtils.routes.auth.signUpEmail.path,
        name: RouterUtils.routes.auth.signUpEmail.name,
        meta: {layout: "home", authRequired: false},
        component: SignUpEmail,
    },

    {
        path: RouterUtils.routes.auth.verifyEmailAddress.path,
        name: RouterUtils.routes.auth.verifyEmailAddress.name,
        meta: {layout: "home", authRequired: false},
        component: VerifyEmailAddress,
    },

    {
        path: RouterUtils.routes.auth.accountCreatedSuccessfully.path,
        name: RouterUtils.routes.auth.accountCreatedSuccessfully.name,
        meta: {layout: "home", authRequired: false},
        component: AccountCreatedSuccessfully,
    },


    {
        path: RouterUtils.routes.auth.logInWithEmail.path,
        name: RouterUtils.routes.auth.logInWithEmail.name,
        meta: {layout: "home", authRequired: false},
        component: LoginWithEmail,
    },


    {
        path: RouterUtils.routes.auth.loginSuccess.path,
        name: RouterUtils.routes.auth.loginSuccess.name,
        meta: {layout: "home", authRequired: false},
        component: LoginSuccess,
    },

    {
        path: RouterUtils.routes.auth.forgotPassword.path,
        name: RouterUtils.routes.auth.forgotPassword.name,
        meta: {layout: "home", authRequired: false},
        component: ForgotPassword,
    },

    {
        path: RouterUtils.routes.auth.forgotPasswordVerifyEmail.path,
        name: RouterUtils.routes.auth.forgotPasswordVerifyEmail.name,
        meta: {layout: "home", authRequired: false},
        component: ForgotPasswordVerifyEmail,
    },

    {
        path: RouterUtils.routes.auth.setNewPassword.path,
        name: RouterUtils.routes.auth.setNewPassword.name,
        meta: {layout: "home", authRequired: false},
        component: SetNewPassword,
    },

    {
        path: RouterUtils.routes.auth.passwordResetSuccessful.path,
        name: RouterUtils.routes.auth.passwordResetSuccessful.name,
        meta: {layout: "home", authRequired: false},
        component: PasswordResetSuccessful,
    },

    {
        path: RouterUtils.routes.auth.signUpSetPassword.path,
        name: RouterUtils.routes.auth.signUpSetPassword.name,
        meta: {layout: "home", authRequired: false},
        component: SignUpSetPassword,
    },

];