class FundTransferRequest {
    nameEnquiry = {
        accountBankCode: null,
        accountNumber: null
    };

    postSingleTransaction = {
        amount: null,
        creditAccountNumber: null,
        debitAccountNumber: null,
        narration: null,
        reference: null
    };

    fundTransfer = {
        amount: null,
        bankName: null,
        bankCode: null,
        creditAccountName: null,
        creditAccountNumber: null,
        debitAccountName: null,
        debitAccountNumber: null,
        narration: null,
        sessionId: null,
        reference: null,
        sourceType: null
    };

    transactionStatusQuery = {
        reference: null
    };


}

export default FundTransferRequest;