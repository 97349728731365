import RouterUtils from "@/util/baseUtils/RouterUtils"
import Home from "@/views/Home";



export default [
    {
        path: RouterUtils.routes.base.home.path,
        name: RouterUtils.routes.base.home.name,
        meta: {layout: "home", authRequired: false},
        component: Home,
    },

];
