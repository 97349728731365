import store from "../../store/store";

class StoreUtils {
    static actions = {
        auth: {
            login: "auth/login",
            initiateEnrollment: "auth/initiateEnrollment",
            completeEnrollment: "auth/completeEnrollment",
            resendOtp: "auth/resendOtp",
            initiatePasswordReset: "auth/initiatePasswordReset",
            completePasswordReset: "auth/completePasswordReset",
            changePassword: "auth/changePassword",
            getUserDetails: "auth/getUserDetails",
        },
        organisation: {
            organisationReadOrganisationsByCustomerId: "organisation/organisationReadOrganisationsByCustomerId",
            organisationUpdateOrganisation: "organisation/organisationUpdateOrganisation",
            organisationTransactionEnquiry: "organisation/organisationTransactionEnquiry",
            organisationBalanceEnquiryOrganisation: "organisation/organisationBalanceEnquiryOrganisation",
            organisationReferralStats: "organisation/organisationReferralStats",
            organisationReadOrganisationById: "organisation/organisationReadOrganisationById",
            organisationSwitchStage: "organisation/organisationSwitchStage",
            organisationReadBalances: "organisation/organisationReadBalances",
            organisationAddOrganisation: "organisation/organisationAddOrganisation",
            organisationReadOrganisationTransactions: "organisation/organisationReadOrganisationTransactions",
        },
        virtualAccount: {
            virtualAccountCreate: "virtualAccount/virtualAccountCreate",
            virtualAccountUpdate: "virtualAccount/virtualAccountUpdate",
            virtualAccountRead: "virtualAccount/virtualAccountRead",
            virtualAccountReadPartners: "virtualAccount/virtualAccountReadPartners",
            virtualAccountReadByOrganisationId: "virtualAccount/virtualAccountReadByOrganisationId",
            virtualAccountReadById: "virtualAccount/virtualAccountReadById",
            virtualAccountReadByAccountNumber: "virtualAccount/virtualAccountReadByAccountNumber",
        },
        wallet: {
            walletCreate: "wallet/walletCreate",
            walletBalanceEnquiry: "wallet/walletBalanceEnquiry",
            walletRetrieve: "wallet/walletRetrieve",
            walletRead: "wallet/walletRead",
            walletBlockAndUnblock: "wallet/walletBlockAndUnblock",
            walletAmountBlockAndUnblock: "wallet/walletAmountBlockAndUnblock",
            walletReadLien: "wallet/walletReadLien",
            walletReadWalletActivityByAccountNumber: "wallet/walletReadWalletActivityByAccountNumber",
            walletReadWalletActivity: "wallet/walletReadWalletActivity",
        },
        fundTransfer: {
            fundTransferNameEnquiry: "fundTransfer/fundTransferNameEnquiry",
            fundTransferBankList: "fundTransfer/fundTransferBankList",
            fundTransferPostSingleTransaction: "fundTransfer/fundTransferPostSingleTransaction",
            fundTransferFundTransfer: "fundTransfer/fundTransferFundTransfer",
            fundTransferRead: "fundTransfer/fundTransferRead",
            fundTransferTransactionStatusQuery: "fundTransfer/fundTransferTransactionStatusQuery",
        },
        customer: {
            customerAddCustomer: "customer/customerAddCustomer",
            customerUpdateCustomer: "customer/customerUpdateCustomer",
            customerReadCustomerById: "customer/customerReadCustomerById",
            customerReadByEmail: "customer/customerReadByEmail",
            customerReadByPhone: "customer/customerReadByPhone",
            customerUpdateStatus: "customer/customerUpdateStatus",
            customerReadInviteByOrganisationId: "customer/customerReadInviteByOrganisationId",
            customerValidateInvite: "customer/customerValidateInvite",
            customerInviteCustomer: "customer/customerInviteCustomer",
        },
        apiKey: {
            apiKeyRead: "apiKey/apiKeyRead",
            apiKeyReadApiKeyByOrganisationId: "apiKey/apiKeyReadApiKeyByOrganisationId",
            apiKeyReadApiKeyById: "apiKey/apiKeyReadApiKeyById",
            apiKeyUpdateApiKey: "apiKey/apiKeyUpdateApiKey",
            apiKeyGenerateApiKey: "apiKey/apiKeyGenerateApiKey",
            apiKeyUpdateWebhookAndCallback: "apiKey/apiKeyUpdateWebhookAndCallback",
        },
    };

    static mutations = {
        auth: {
            updateLoading: "auth/updateLoading",
            updateUserInfo: "auth/updateUserInfo",
            updateToken: "auth/updateToken",
            updateScreen: "auth/updateScreen",
            updateSignInFormData: "auth/updateSignInFormData",
            updateSignUpFormData: "auth/updateSignUpFormData",
            updateMid:"auth/updateMid"
        },
        organisation: {
            updateLoading:"organisation/updateLoading",
            updateReadOrganisationsByCustomerId: "organisation/updateReadOrganisationsByCustomerId",
            updateSwitchStage: "organisation/updateSwitchStage",
            updateReadBalances: "organisation/updateReadBalances",
            updateReadOrganisationTransactions: "organisation/updateReadOrganisationTransactions",
            updateReferralStats: "organisation/updateReferralStats",
            updateReadOrganisationById: "organisation/updateReadOrganisationById",
        },
        virtualAccount: {
            updateLoading:"virtualAccount/updateLoading",
            updateVirtualAccount: "virtualAccount/updateVirtualAccount",
            updateVirtualAccounts: "virtualAccount/updateVirtualAccounts",
            updateReadPartners: "virtualAccount/updateReadPartners",
            updateReadByOrganisationId: "virtualAccount/updateReadByOrganisationId",
            updateReadById: "virtualAccount/updateReadById",
            updateReadByAccountNumber: "virtualAccount/updateReadByAccountNumber",
        },
        wallet: {
            updateLoading:"wallet/updateLoading",
            updateWallet: "wallet/updateWallet",
            updateWallets: "wallet/updateWallets",
            updateBalanceEnquiry: "wallet/updateBalanceEnquiry",
            updateRetrieve: "wallet/updateRetrieve",
            updateReadLien: "wallet/updateReadLien",
            updateReadWalletActivityByAccountNumber: "wallet/updateReadWalletActivityByAccountNumber",
            updateReadWalletActivity: "wallet/updateReadWalletActivity",
        },
        fundTransfer: {
            updateLoading:"fundTransfer/updateLoading",
            updateNameEnquiry: "fundTransfer/updateNameEnquiry",
            updateBankList: "fundTransfer/updateBankList",
            updatePostSingleTransaction: "fundTransfer/updatePostSingleTransaction",
            updateFundTransfer: "fundTransfer/updateFundTransfer",
            updateRead: "fundTransfer/updateRead",
            updateTransactionStatusQuery: "fundTransfer/updateTransactionStatusQuery",
            updateTransferToOtherBanksForm: "fundTransfer/updateTransferToOtherBanksForm",
            updateTransferToAfropayForm: "fundTransfer/updateTransferToAfropayForm",
        },
        customer: {
            updateLoading:"customer/updateLoading",
            updateCustomer: "customer/updateCustomer",
            updateUpdateCustomer: "customer/updateUpdateCustomer",
            updateReadCustomerById: "customer/updateReadCustomerById",
            updateReadByEmail: "customer/updateReadByEmail",
            updateReadByPhone: "customer/updateReadByPhone",
            updateReadInviteByOrganisationId: "customer/updateReadInviteByOrganisationId",
        },
        apiKey: {
            updateLoading:"apiKey/updateLoading",
            updateApiKey: "apiKey/updateApiKey",
            updateApiKeys: "apiKey/updateApiKeys",
            updateReadApiKeyByOrganisationId: "apiKey/updateReadApiKeyByOrganisationId",
            updateReadApiKeyById: "apiKey/updateReadApiKeyById",
            updateUpdateApiKey: "apiKey/updateUpdateApiKey",
            updateUpdateWebhookAndCallback: "apiKey/updateUpdateWebhookAndCallback",
        },
    };

    static getters = {
        auth: {
            getUserInfo: "auth/getUserInfo",
            getToken: "auth/getToken",
            getSignInFormData: "auth/getSignInFormData",
            getSignUpFormData: "auth/getSignUpFormData",
            getMid:"auth/getMid"
        },
        organisation: {
            getReadOrganisationsByCustomerId: "organisation/getReadOrganisationsByCustomerId",
            getOrganisation: "organisation/getOrganisation",
            getSwitchStage: "organisation/getSwitchStage",
            getReadBalances: "organisation/getReadBalances",
            getReadOrganisationTransactions: "organisation/getReadOrganisationTransactions",
            getReferralStats: "organisation/getReferralStats",
            getReadOrganisationById: "organisation/getReadOrganisationById",
        },
        virtualAccount: {
            getVirtualAccount: "virtualAccount/getVirtualAccount",
            getVirtualAccounts: "virtualAccount/getVirtualAccounts",
            getReadPartners: "virtualAccount/getReadPartners",
            getReadByOrganisationId: "virtualAccount/getReadByOrganisationId",
            getReadById: "virtualAccount/getReadById",
            getReadByAccountNumber: "virtualAccount/getReadByAccountNumber",
        },
        wallet: {
            getWallet: "wallet/getWallet",
            getWallets: "wallet/getWallets",
            getBalanceEnquiry: "wallet/getBalanceEnquiry",
            getRetrieve: "wallet/getRetrieve",
            getReadLien: "wallet/getReadLien",
            getReadWalletActivityByAccountNumber: "wallet/getReadWalletActivityByAccountNumber",
            getReadWalletActivity: "wallet/getReadWalletActivity",
        },
        fundTransfer: {
            getNameEnquiry: "fundTransfer/getNameEnquiry",
            getBankList: "fundTransfer/getBankList",
            getPostSingleTransaction: "fundTransfer/getPostSingleTransaction",
            getFundTransfer: "fundTransfer/getFundTransfer",
            getRead: "fundTransfer/getRead",
            getTransactionStatusQuery: "fundTransfer/getTransactionStatusQuery",
            getTransferToOtherBanksForm: "fundTransfer/getTransferToOtherBanksForm",
            getTransferToAfropayForm: "fundTransfer/getTransferToAfropayForm",
        },
        customer: {
            getCustomer: "customer/getCustomer",
            getUpdateCustomer: "customer/getUpdateCustomer",
            getReadCustomerById: "customer/getReadCustomerById",
            getReadByEmail: "customer/getReadByEmail",
            getReadByPhone: "customer/getReadByPhone",
            getReadInviteByOrganisationId: "customer/getReadInviteByOrganisationId",
        },
        apiKey: {
            getApiKey: "apiKey/getApiKey",
            getApiKeys: "apiKey/getApiKeys",
            getReadApiKeyByOrganisationId: "apiKey/getReadApiKeyByOrganisationId",
            getReadApiKeyById: "apiKey/getReadApiKeyById",
            getUpdateApiKey: "apiKey/getUpdateApiKey",
            getUpdateWebhookAndCallback: "apiKey/getUpdateWebhookAndCallback",
        },
    };

    static dispatch(actionToDispatch, payload) {
        return store.dispatch(actionToDispatch, payload, { root: true });
    }

    static commit(mutationToCommit, payload) {
        return store.commit(mutationToCommit, payload, { root: true });
    }

    static rootGetters(getter, payload) {
        console.log(getter)
        if (payload) {
            return store.getters[getter](payload);
        } else {
            return store.getters[getter];
        }
    }
}

export default StoreUtils;
