<template>
  <div>
    <dashboard-layout>
      <DashBoardTransactionsView/>
    </dashboard-layout>
  </div>
</template>

<script>
import DashBoardTransactionsView from '../../components/dashboard/DashBoardTransactionsView.vue';
import DashboardLayout from "@/layout/DashboardLayout.vue";

export default {
    name: "Transactions",
    components: {DashboardLayout, DashBoardTransactionsView }
}
</script>
<style>
    
</style>