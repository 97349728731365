<template>
  <div>
    <forgot-passcode/>
  </div>
</template>

<script>
import ForgotPasscode from "../../components/auth/signIn/ForgotPasscode";
export default {
  name: "ForgotPassword",
  components: {ForgotPasscode}
}
</script>

<style scoped>

</style>