class WalletRequest {
    create = {
        bvn: null,
        dob: null,
        email: null,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        countryCode: null,
        currency: null
    };

    balanceEnquiry = {
        accountNumber: null
    };

    retrieve = {
        accountNumber: null
    };

    read = {
        page: 1
    };

    walletBlockAndUnblock = {
        accountNumber: null,
        allowCredit: null,
        allowDebit: null,
        actionType: null,
        reference: null,
        amount: null,
    };

    amountBlockAndUnblock = {
        accountNumber: null,
        amount: null,
        reference: null,
        actionType: null//BLOCK or UNBLOCK
    };

    readLien = {
        accountNumber: null
    };

    readWalletActivityByAccountNumber = {
        accountNumber: null,
        page:1
    };


}

export default WalletRequest;