<template>
  <div>
    <dashboard-layout>
      <dash-board-side-menu/>
    </dashboard-layout>
  </div>
</template>

<script>
import DashBoardSideMenu from "../../components/dashboard/DashBoardSideMenu";
import DashboardLayout from "@/layout/DashboardLayout.vue";
export default {
  name: "DashBoardSideBar",
  components: {DashboardLayout, DashBoardSideMenu}
}
</script>

<style scoped>

</style>