<template>
  <div>
    <create-account-email/>
  </div>
</template>

<script>
import CreateAccountEmail from "../../components/auth/signUp/CreateAccountEmail";
export default {
  name: "SignUpEmail",
  components: {CreateAccountEmail}
}
</script>

<style scoped>

</style>