import swal from "sweetalert";
import StoreUtils from "@/util/baseUtils/StoreUtils";
import CustomerService from "@/service/CustomerService";
import CustomerRequest from "@/model/request/CustomerRequest";
import CustomerResponse from "@/model/response/CustomerResponse";



export const state = {
    loading: false,
    customer: null,
    // validateInvite: null,
    // inviteCustomer: null,
    updateCustomer: new CustomerResponse().updateCustomer,
    readCustomerById: new CustomerResponse().readCustomerById,
    readByEmail: new CustomerResponse().readByEmail,
    readByPhone: new CustomerResponse().readByPhone,
    readInviteByOrganisationId: new CustomerResponse().readInviteByOrganisationId,
};

export const getters = {
    getCustomer: (state) => {
        return state.customer;
    },
    // getValidateInvite: (state) => {
    //     return state.validateInvite;
    // },
    // getInviteCustomer: (state) => {
    //     return state.inviteCustomer;
    // },
    getUpdateCustomer: (state) => {
        return state.updateCustomer;
    },
    getReadCustomerById: (state) => {
        return state.readCustomerById;
    },
    getReadByEmail: (state) => {
        return state.readByEmail;
    },
    getReadByPhone: (state) => {
        return state.readByPhone;
    },
    getReadInviteByOrganisationId: (state) => {
        return state.readInviteByOrganisationId;
    },
};


export const mutations = {
    updateLoading(state, payload){
        state.loading = payload
    },
    updateCustomer(state, payload){
        state.customer = payload
    },
    // updateValidateInvite(state, payload){
    //     state.validateInvite = payload
    // },
    // updateInviteCustomer(state, payload){
    //     state.inviteCustomer = payload
    // },
    updateUpdateCustomer(state, payload){
        state.updateCustomer = payload
    },
    updateReadCustomerById(state, payload){
        state.readCustomerById = payload
    },
    updateReadByEmail(state, payload){
        state.readByEmail = payload
    },
    updateReadByPhone(state, payload){
        state.readByPhone = payload
    },
    updateReadInviteByOrganisationId(state, payload){
        state.readInviteByOrganisationId = payload
    },
};

export const actions = {
    customerAddCustomer({}, payload = new CustomerRequest().addCustomer){
        StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, true)
        return CustomerService.callAddCustomerApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    customerUpdateCustomer({}, payload = new CustomerRequest().updateCustomer){
        StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, true)
        return CustomerService.callUpdateCustomerApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
                StoreUtils.commit(StoreUtils.mutations.customer.updateUpdateCustomer, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    customerReadCustomerById({}, payload = new CustomerRequest().readCustomerById){
        StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, true)
        return CustomerService.callReadCustomerByIdApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
                StoreUtils.commit(StoreUtils.mutations.customer.updateReadCustomerById, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    customerReadByEmail({}, payload = new CustomerRequest().readByEmail){
        StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, true)
        return CustomerService.callReadByEmailApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
                StoreUtils.commit(StoreUtils.mutations.customer.updateReadByEmail, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    customerReadByPhone({}, payload = new CustomerRequest().readByPhone){
        StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, true)
        return CustomerService.callReadByPhoneApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
                StoreUtils.commit(StoreUtils.mutations.customer.updateReadByPhone, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    customerUpdateStatus({}, payload = new CustomerRequest().updateStatus){
        StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, true)
        return CustomerService.callUpdateStatusApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, false)
        })
    },


    // eslint-disable-next-line no-empty-pattern
    customerReadInviteByOrganisationId({}, payload = {}){
        StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, true)
        return CustomerService.callReadInviteByOrganisationIdApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === '00'){
                StoreUtils.commit(StoreUtils.mutations.customer.updateReadInviteByOrganisationId, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, false)
        })
    },


    // eslint-disable-next-line no-empty-pattern
    customerValidateInvite({}, payload = new CustomerRequest().validateInvite){
        StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, true)
        return CustomerService.callValidateInviteApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, false)
        })
    },


    // eslint-disable-next-line no-empty-pattern
    customerInviteCustomer({}, payload = new CustomerRequest().inviteCustomer){
        StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, true)
        return CustomerService.callInviteCustomerApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.customer.updateLoading, false)
        })
    },

};