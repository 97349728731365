<template>
  <div>
    <dashboard-layout>
      <dash-board-virtual-accounts-view/>
    </dashboard-layout>
  </div>
</template>

<script>
import DashBoardVirtualAccountsView from "@/components/dashboard/DashBoardVirtualAccountsView.vue";
import DashboardLayout from "@/layout/DashboardLayout.vue";

export default {
  name: "VirtualAccounts",
  components: {DashboardLayout, DashBoardVirtualAccountsView}
}
</script>

<style scoped>

</style>