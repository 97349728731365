import client  from "./BaseService";

export default {
    callReadPartnersApi() {
        return client.apiClient.post("virtual-account/read-partners", {});
    },
    callCreateApi(payload) {
        return client.apiClient.post("virtual-account/create", payload);
    },
    callReadApi(){
        return client.apiClient.post("virtual-account/read", {});
    },
    callReadByOrganisationIdApi(){
        return client.apiClient.post("virtual-account/read-by-organisation-id",{})
    },
    callReadByIdApi(payload){
        return client.apiClient.post("virtual-account/read-by-id",payload)
    },
    callReadByAccountNumberApi(payload){
        return client.apiClient.post("virtual-account/read-by-account-number",payload)
    },
    callUpdateApi(payload){
        return client.apiClient.post("virtual-account/update",payload)
    },
};