<template>
  <div class="alpha">

    <div class="image">
      <img src="@/assets/afropaylogo.svg" alt="inter-switch-logo" >
    </div>

    <div class="text-area">
      <h3>Verify email address</h3>
      <p>Enter OTP sent to {{this.signUpFormData.customerEmail}} </p>
    </div>

    <div class="form-alpha">
      <form @submit.prevent="completeEnrollment">

        <div class="pin-code" ref="pinContainer">
          <input type="number" autofocus v-model="pins[0]" maxlength="1" @keyup="handleKeyUp($event, 0)" @keydown="handleKeyDown($event, 0)" required="required">
          <input type="number" v-model="pins[1]" maxlength="1" @keyup="handleKeyUp($event, 1)" @keydown="handleKeyDown($event, 1)" required="required">
          <input type="number" v-model="pins[2]" maxlength="1" @keyup="handleKeyUp($event, 2)" @keydown="handleKeyDown($event, 2)" required="required">
          <input type="number" v-model="pins[3]" maxlength="1" @keyup="handleKeyUp($event, 3)" @keydown="handleKeyDown($event, 3)" required="required">
          <input type="number" v-model="pins[4]" maxlength="1" @keyup="handleKeyUp($event, 2)" @keydown="handleKeyDown($event, 4)" required="required">
          <input type="number" v-model="pins[5]" maxlength="1" @keyup="handleKeyUp($event, 3)" @keydown="handleKeyDown($event, 5)" required="required">
        </div>

        <div class="submit">
<!--          <input type="submit" class="submit-btn"   value="Verify email"/>-->
          <base-button :loading="loading">Verify email</base-button>
        </div>

      </form>
    </div>

    <div class="last-text">
      <p>Didn’t get a code? <span @click="resendOtp">Resend OTP</span></p>
    </div>

  </div>
</template>

<script>
import RouterUtils from "../../../util/baseUtils/RouterUtils";
import AuthenticationRequest from "../../../model/request/AuthenticationRequest";
import {mapState} from "vuex";
import StoreUtils from "../../../util/baseUtils/StoreUtils";
import BaseButton from "@/components/baseComponents/buttons/BaseButton.vue";

export default {
  name: "CreateAccountVerifyEmailAddress",
  components: {BaseButton},
  data() {
    return {
      pins: [],
      model: new AuthenticationRequest().completeEnrollment,
    };
  },
  computed:{
    ...mapState({
      loading: state => state.auth.loading,
      auth: state => state.auth
    }),
    signUpFormData() {
      return StoreUtils.rootGetters(StoreUtils.getters.auth.getSignUpFormData)
    },
  },
  methods: {
    completeEnrollment(){
      StoreUtils.commit(StoreUtils.mutations.auth.updateSignUpFormData, {
        customerOtp: this.pins.toString().replaceAll(',',''),
        customerCountry: this.signUpFormData.customerCountry,
        customerBusinessName: this.signUpFormData.customerBusinessName,
        customerCountryCode: this.signUpFormData.customerCountryCode,
        customerEmail: this.signUpFormData.customerEmail,
        customerFirstName: this.signUpFormData.customerFirstName,
        customerLastName: this.signUpFormData.customerLastName,
        customerPhone: this.signUpFormData.customerPhone,
        source: this.signUpFormData.source,
      })
      RouterUtils.navigateTo(RouterUtils.routes.auth.signUpSetPassword.name)
    },

    resendOtp(){
      StoreUtils.dispatch(StoreUtils.actions.auth.resendOtp, {
        customerEmail: this.signUpFormData.customerEmail,
        source: this.signUpFormData.source
      })
    },

    handleKeyUp(event) {
      const target = event.target;

      const maxLength = parseInt(target.attributes.maxlength.value, 10);
      const myLength = target.value.length;

      if (myLength >= maxLength) {
        const next = target.nextElementSibling;
        if (next && next.tagName.toLowerCase() === 'input') {
          next.focus();
        }
      }

      if (myLength === 0) {
        const prev = target.previousElementSibling;
        if (prev && prev.tagName.toLowerCase() === 'input') {
          prev.focus();
        }
      }

      this.$emit('input', this.pins.join(''));
    },
    handleKeyDown(event) {
      const target = event.target;
      target.value = '';
    },
  },
}
</script>

<style scoped>
.alpha{
  margin-top: 12%;
}

.text-area h3{
  padding-bottom: 0;
  margin-bottom: 0;
  text-align: center;
  letter-spacing: 0.5px;
  color: #101828;
  font-weight: 600;
  font-size: 22px;
  line-height: 38px;
}

.text-area p{
  padding-top: 2px;
  margin-top: 2px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #667085;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.text-area{
  margin-bottom: 1%;
}


.image{
  display: flex;
  justify-content: center;
  margin: 0.8%;
}

.submit{
  margin-left: 38%;
  margin-right: 38%;
}


.last-text{
  margin-top: 1.5%;
  text-align: center;
  color: #667085;
}

span{
  color: #33536F;
}



.pin-code{
  padding: 0;
  display: flex;
  justify-content:space-evenly;
  margin-bottom: 2%;
  margin-top: 2%;
  margin-left: 38%;
  margin-right: 38%;
}

.pin-code input {
  border: 1px solid #D0D5DD;
  text-align: center;
  width: 48px;
  height:48px;
  font-size: 36px;
  background-color: #F3F3F3;
  margin-right:5px;
  border-radius: 6px;
  color: #436686;
}



.pin-code input:focus {
  border: 1px solid #436686;
  outline:none;
  color: #436686;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


</style>