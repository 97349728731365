import RouterUtils from "@/util/baseUtils/RouterUtils"
import DashBoardSideBar from "@/views/DashBoard/DashBoardSideBar";
import OverView from "@/views/DashBoard/OverView";
import WalletActivities from "@/views/DashBoard/WalletActivities";
import Transactions from "@/views/DashBoard/Transactions";
import VirtualAccounts from "@/views/DashBoard/VirtualAccounts.vue";
import WalletsManagement from "@/views/DashBoard/WalletsManagement.vue";
import FundsTransfer from "@/views/DashBoard/FundsTransfer.vue";
import Settings from "@/views/DashBoard/Settings.vue";
import SettingsUser from "@/views/DashBoard/SettingsUser.vue";
import APIKeysAndWebhooks from "@/views/DashBoard/APIKeysAndWebhooks.vue";


export default [
    {
        path: RouterUtils.routes.dashboard.homeDashBoard.path,
        name: RouterUtils.routes.dashboard.homeDashBoard.name,
        meta: {layout: "home", authRequired: false},
        component: DashBoardSideBar,
        // redirect: { name: 'overView' },
    },
    {
        path: RouterUtils.routes.dashboard.overView.path,
        name: RouterUtils.routes.dashboard.overView.name,
        meta: {layout: "home", authRequired: false},
        component: OverView,
    },
    {
        path: RouterUtils.routes.dashboard.walletActivities.path,
        name: RouterUtils.routes.dashboard.walletActivities.name,
        meta: {layout: "home", authRequired: false},
        component: WalletActivities,
    },
    {
        path: RouterUtils.routes.dashboard.transactions.path,
        name: RouterUtils.routes.dashboard.transactions.name,
        meta: {layout: "home", authRequired: false},
        component: Transactions,
    },
    {
        path: RouterUtils.routes.dashboard.virtualAccounts.path,
        name: RouterUtils.routes.dashboard.virtualAccounts.name,
        meta: {layout: "home", authRequired: false},
        component: VirtualAccounts,
    },
    {
        path: RouterUtils.routes.dashboard.virtualAccounts.path,
        name: RouterUtils.routes.dashboard.virtualAccounts.name,
        meta: {layout: "home", authRequired: false},
        component: VirtualAccounts,
    },
    {
        path: RouterUtils.routes.dashboard.walletsManagement.path,
        name: RouterUtils.routes.dashboard.walletsManagement.name,
        meta: {layout: "home", authRequired: false},
        component: WalletsManagement,
    },
    {
        path: RouterUtils.routes.dashboard.fundsTransfer.path,
        name: RouterUtils.routes.dashboard.fundsTransfer.name,
        meta: {layout: "home", authRequired: false},
        component: FundsTransfer,
    },
    {
        path: RouterUtils.routes.dashboard.settings.path,
        name: RouterUtils.routes.dashboard.settings.name,
        meta: {layout: "home", authRequired: false},
        component: Settings,
    },
    {
        path: RouterUtils.routes.dashboard.settingsUser.path,
        name: RouterUtils.routes.dashboard.settingsUser.name,
        meta: {layout: "home", authRequired: false},
        component: SettingsUser,
    },
    {
        path: RouterUtils.routes.dashboard.aPIKeysAndWebhooks.path,
        name: RouterUtils.routes.dashboard.aPIKeysAndWebhooks.name,
        meta: {layout: "home", authRequired: false},
        component: APIKeysAndWebhooks,
    },
];