import client  from "./BaseService";

export default {
    callUpdateCustomerApi(payload) {
        return client.apiClient.post("customer/update", payload);
    },
    callReadCustomerByIdApi(payload) {
        return client.apiClient.post("customer/read-by-id", payload);
    },
    callReadByEmailApi(payload) {
        return client.apiClient.post("customer/read-by-email", payload);
    },
    callReadByPhoneApi(payload) {
        return client.apiClient.post("customer/read-by-phone", payload);
    },
    callAddCustomerApi(payload) {
        return client.apiClient.post("customer/create", payload);
    },
    callUpdateStatusApi(payload) {
        return client.apiClient.post("customer/update-status", payload);
    },
    callReadInviteByOrganisationIdApi() {
        return client.apiClient.post("customer/read-invite-by-organisation-id", {});
    },
    callValidateInviteApi(payload) {
        return client.apiClient.post("customer/validate-invite", payload);
    },
    callInviteCustomerApi(payload) {
        return client.apiClient.post("customer/invite", payload);
    },
};