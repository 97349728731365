<template>
  <div class="alpha">
    <dash-board-side-menu/>
    <dash-content>
      <invite-user-modal @close="hideDialog" v-if="dialogIsVisible"/>

      <div class="section-4">
        <p class="section-4-header">Settings</p>
      </div>

      <div class="section-2">
        <p @click="profile">Profile</p>
        <p @click="webHooks">API Keys And Webhooks</p>
        <p class="active">User</p>
      </div>

      <br/>

      <div class="section-5">

        <div class="second">
          <p class="second-text">Users</p>
<!--          <p class="section-5-text-1" @click="showDialog">Invite User</p>-->
        </div>

<!--        <div class="empty-state" v-if="this.readInviteByOrganisationId.data.length === 0">-->
<!--          <img src="@/assets/empty.svg" alt="empty">-->
<!--          <p class="empty-state-text-1">You have nothing to see</p>-->
<!--          <p class="empty-state-text-2">This is where your Users will appear</p>-->
<!--          <p class="empty-state-text-3">-->
<!--            <i class='bx bx-plus' ></i>-->
<!--            Transaction-->
<!--          </p>-->
<!--        </div>-->



        <div class="table">
          <table>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>User Role</th>
              <th>Status</th>
              <th></th>
            </tr>

            <tbody>
            <tr>
              <td>{{this.userInfo.customerFirstName}}</td>
              <td>{{this.userInfo.customerLastName}}</td>
              <td>{{this.userInfo.customerEmail}}</td>
              <td>{{this.userInfo.customerPhone}}</td>
              <td>{{this.userInfo.currentOrganisation.customerOrganisationRole}}</td>
              <td>{{this.userInfo.currentOrganisation.customerOrganisationStatus}}</td>
              <td></td>
            </tr>
            </tbody>

          </table>
          <div class="pagination">
            <button @click="previousPage" :disabled="currentPage === 1" class="previous">Previous</button>
            <div class="page-indicator">
              Page {{ currentPage }} of {{ totalPages }}
            </div>
            <button @click="nextPage" :disabled="currentPage === totalPages" class="previous">Next</button>
          </div>
        </div>
      </div>

      <br/>
      <br/>

      <div class="section-5">

        <div class="second">
          <p class="second-text">Pending Invites</p>
          <p class="section-5-text-1" @click="showDialog">Invite User</p>
        </div>


        <div class="empty-state" v-if="this.readInviteByOrganisationId.data.length === 0">
          <img src="@/assets/empty.svg" alt="empty">
          <p class="empty-state-text-1">You have nothing to see</p>
          <p class="empty-state-text-2">This is where your Users will appear</p>
          <p class="empty-state-text-3" @click="showDialog">
            <i class='bx bx-plus' ></i>
            Invite User
          </p>
        </div>



                        <div class="table" v-if="this.readInviteByOrganisationId.data.length >0">
                          <table>
                            <tr>
                              <th>Email</th>
                              <th>Role</th>
                              <th>Status</th>
                              <th>Invite Link</th>
                            </tr>

                            <tbody v-for="child in paginatedItems" :key="child.key">
                            <tr>
                              <td>{{child.inviteCustomerEmail}}</td>
                              <td>{{child.inviteCustomerRole}}</td>
                              <td>{{child.inviteStatus}}</td>
                              <td>{{child.inviteLink}}</td>
                            </tr>
                            </tbody>

                          </table>
                          <div class="pagination">
                            <button @click="previousPage" :disabled="currentPage === 1" class="previous">Previous</button>
                            <div class="page-indicator">
                              Page {{ currentPage }} of {{ totalPages }}
                            </div>
                            <button @click="nextPage" :disabled="currentPage === totalPages" class="previous">Next</button>
                          </div>
                        </div>
      </div>


    </dash-content>
  </div>
</template>

<script>
import RouterUtils from "@/util/baseUtils/RouterUtils";
import DashBoardSideMenu from "@/components/dashboard/DashBoardSideMenu.vue";
import DashContent from "@/components/baseComponents/dash/DashContent.vue";
import InviteUserModal from "@/components/baseComponents/modals/InviteUserModal.vue";
import {mapState} from "vuex";
import StoreUtils from "@/util/baseUtils/StoreUtils";

export default {
  name: "DashBoardSettingsUserView",
  components: {InviteUserModal, DashContent, DashBoardSideMenu},
  data() {
    return {
      dialogIsVisible: false,
      currentPage: 1,
      itemsPerPage: 6,
    };
  },
  computed:{
    ...mapState({
      loading: state => state.customer.loading,
      auth: state => state.auth,
      userInfo: state => state.auth.userInfo,
      readInviteByOrganisationId: state => state.customer.readInviteByOrganisationId,
    }),
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.readInviteByOrganisationId.data.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.readInviteByOrganisationId.data.length / this.itemsPerPage);
    },
  },
  methods:{
    profile(){
      RouterUtils.navigateTo(RouterUtils.routes.dashboard.settings.name)
    },
    webHooks(){
      RouterUtils.navigateTo(RouterUtils.routes.dashboard.aPIKeysAndWebhooks.name)
    },
    hideDialog() {
      this.dialogIsVisible = false;
    },
    showDialog() {
      this.dialogIsVisible = true;
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },

    goToPage(pageNumber) {
      if (pageNumber > 0 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
      }
    },
  },
  async mounted() {
    await StoreUtils.dispatch(StoreUtils.actions.customer.customerReadInviteByOrganisationId,)
  },
}
</script>

<style scoped>
.alpha{
  display: flex;
}
.section-1{
  display: flex;
  justify-content: right;
  align-content: center;
  align-items: center;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  padding-right: 3%;
  background-color: #FFFFFF;
}
.vl{
  border-left: 1.5px solid #E7E8F0;
  height: 35px;
  margin-left: 1%;
  margin-right: 1%;
}
.profile-name{
  padding-left: 1%;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #58596C;
}

.section-4-header{
  padding-left: 1.5%;
  margin-top: 1.5%;
  font-weight: 700;
  font-size: 19px;
  line-height: 25px;
  color: #353542;
}

.section-2{
  padding-left: 3%;
  margin-left: 3%;
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 470px;
  height: 46px;
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 8px;
  color: #667085;
}

.section-2 p{
  margin-right: 11%;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.active{
  display: flex;
  align-content: center;
  align-items: center;
  color: #0765FF;
  height: 34px;
  padding-right: 30px;
  padding-left: 30px;
  background: #ECF2FF;
  border-radius: 6px;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 2%;
}
.table{
  margin-left: 2%;
  margin-right: 3%;
}

tr{
  border: 1px solid #E3EBF6;
}

th {

  background-color: #F9FBFD;
  padding: 10px;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 14px;
  color: #667085;
  text-align: center;
}

td {
  /*border: 1px solid #E3EBF6;*/
  text-align: center;
  align-items: center;
  align-content: center;
  padding: 13px 8px;
  /*letter-spacing: 1px;*/
  color: #667085;
  font-weight: 200;
  font-size: 15px;
  /*border-bottom: 1px solid #E3EBF6;*/
  text-transform: lowercase;
}

td::first-letter {
  text-transform: capitalize;
}

.empty-state{
  text-align: center;
  margin-top: 7%;
  margin-right: 8%;
}


.empty-state-text-1{
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #353542;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}

.empty-state-text-2{
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #353542;
  padding-bottom: 1.25%;
}

.empty-state-text-3{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;
  width: 130px;
  margin-right: auto;
  margin-left: auto;
  height: 35px;
  background: #0765FF;
  color: #FFFFFF;
  border: 1px solid #0765FF;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 5px;
  font-size: 13px;
}

.empty-state-text-3:hover{
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.section-5-text-1{
  display: inline-block;
  padding: 7px 16px;
  background: #1570EF;
  border: 1px solid #1570EF;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 5px;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: #FFFFFF;
  /*margin-left: 89%;*/
}

.pagination{
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}

.previous{
  /*display: flex;*/
  /*align-content: center;*/
  /*align-items: center;*/
  text-align: center;
  padding: 8px 14px;
  gap: 8px;
  font-size: 12px;
  width: 120px;
  height: 32px;
  background: #1570EF;
  color: #ffffff;
  border: 1px solid #1570EF;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
}

.previous:hover{
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.page-indicator{
  color: #667085;
  font-weight: 200;
  font-size: 15px;
}

.second{
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  margin-right: 5%;
  margin-left: 3%;
}

.second-text{
  font-weight: 700;
  font-size: 19px;
  line-height: 25px;
  color: #353542;
}

</style>