<template>
  <div>
    <dashboard-layout>
      <dash-board-a-p-i-keys-and-webhooks-view/>
    </dashboard-layout>
  </div>
</template>

<script>
import DashBoardAPIKeysAndWebhooksView from "@/components/dashboard/DashBoardAPIKeysAndWebhooksView.vue";
import DashboardLayout from "@/layout/DashboardLayout.vue";

export default {
  name: "APIKeysAndWebhooks",
  components: {DashboardLayout, DashBoardAPIKeysAndWebhooksView}
}
</script>

<style scoped>

</style>