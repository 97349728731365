<template>
  <div>
    <dashboard-layout>
      <dash-board-over-view/>
    </dashboard-layout>
  </div>
</template>

<script>
import DashBoardOverView from "../../components/dashboard/DashBoardOverView";
import DashboardLayout from "@/layout/DashboardLayout.vue";
export default {
  name: "OverView",
  components: {DashboardLayout, DashBoardOverView}
}
</script>

<style scoped>

</style>