class AuthenticationRequest {
  login = {
    deviceId: "",
    deviceName: "",
    pushId: "",
    source: "",
    devicePlatform: "",
    ipAddress: "",
    latitude: "",
    longitude: "",
    password: null,
    username: null,
    status: "",
    profileStatus: "",
    countryCode: "",
    idToken: ""
  };

  initiateEnrollment = {
    customerCountry: null,
    customerBusinessName: null,
    customerCountryCode: 234,
    customerEmail: null,
    customerFirstName: null,
    customerLastName: null,
    customerPhone: null,
    source: "WEB"
  };

  completeEnrollment = {
    customerOtp: null,
    customerPassword: null,
    customerConfirmPassword: null,
    customerEmail: null,
    customerBusinessName: null,
    customerReferralCode:""
  };

  resendOtp = {
    customerEmail: null,
    source: "WEB"
  };

  initiateResetPassword = {
    customerEmail: null,
    source: "WEB"
  };

  completeResetPassword = {
    customerEmail: null,
    customerPassword: null,
    customerPasswordConfirmation: null,
    customerOtp: null
  };

  changePassword = {
    customerOldPassword: null,
    customerPassword: null,
    customerPasswordConfirmation: null,
    customerEmail: null
  };

  resetPin = {
    pin: null,
    pinConfirmation: null,
    otp: null
  };
}

export default AuthenticationRequest;
