<template>
  <div>
    <sign-in-success/>
  </div>
</template>

<script>
import SignInSuccess from "../../components/auth/signIn/SignInSuccess";
export default {
  name: "LoginSuccess",
  components: {SignInSuccess}
}
</script>

<style scoped>

</style>