<template>
  <div>
    <dashboard-layout>
      <dash-board-funds-transfer-view/>
    </dashboard-layout>
  </div>
</template>

<script>
import DashBoardFundsTransferView from "@/components/dashboard/DashBoardFundsTransferView.vue";
import DashboardLayout from "@/layout/DashboardLayout.vue";

export default {
  name: "FundsTransfer",
  components: {DashboardLayout, DashBoardFundsTransferView}
}
</script>

<style scoped>

</style>