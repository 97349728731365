<template>
  <div class="outer">

    <div class="sidebar" v-show="!mobile">

      <div class="logo">
        <img src="@/assets/afologo.svg" alt="logo" class="afo-logo" />
      </div>

      <ul class="nav-list" >


        <router-link to="/over-view">
          <li>
            <a class="hover">
              <img src="@/assets/dashboardlogo.svg" alt="logo" class="link-img" />
              <span class="links-name">Dashboard</span>
            </a>
          </li>
        </router-link>

        <router-link to="/wallet-activities">
          <li>
          <a class="hover">
            <img src="@/assets/pie-chart.svg" alt="logo" class="link-img" />
            <span class="links-name">Wallet Activities</span>
          </a>
        </li>

        </router-link>


        <router-link to="/transactions">
          <li>
            <a class="hover">
              <img src="@/assets/send.svg" alt="logo" class="link-img" />
              <span class="links-name">Transactions</span>
            </a>
          </li>
        </router-link>


        <router-link to="/virtual-accounts">
        <li>
          <a class="hover">
            <img src="../../assets/virtacc.svg" alt="logo" class="link-img" />
            <span class="links-name">Virtual Accounts</span>
          </a>
        </li>
        </router-link>


        <router-link to="/wallets-management">
        <li>
          <a class="hover">
            <img src="../../assets/walletmana.svg" alt="logo" class="link-img" />
            <span class="links-name wallets">Wallets Management</span>
          </a>
        </li>
        </router-link>

        <router-link to="/funds-transfer">
        <li>
          <a class="hover">
            <img src="../../assets/coin-stack.svg" alt="logo" class="link-img" />
            <span class="links-name">Funds Transfer</span>
          </a>
        </li>
        </router-link>

        <router-link to="/settings">
        <li>
          <a class="hover">
            <img src="../../assets/settings.svg" alt="logo" class="link-img" />
            <span class="links-name">Settings</span>
          </a>
        </li>
        </router-link>

      </ul>


      <div class="logout links-name">
        <img src="@/assets/Avatar.svg" alt="logo"  />
        <div class="links-name-separate">
          <span class="links-name lower">{{this.userInfo.customerFirstName}}</span>
          <span class="links-name lower">{{this.userInfo.customerLastName}}</span>
        </div>
        <img src="@/assets/log-out.svg" alt="logo" @click="logOut"  />
      </div>


    </div>


    <div class="icon">
      <i @click="toggleMobileNav" class='bx bx-menu-alt-right' v-show="mobile" :class="{ 'icon-active': mobileNav }"></i>
    </div>
    <transition name="mobile-nav">
      <ul v-show="mobileNav" class="dropdown-nav">
        <li @click="toggleMobileNav2"><router-link :to="{name: 'HomeView'}" class="link">Home</router-link></li>
        <li @click="toggleMobileNav2"><router-link :to="{name: 'TRANSACTION'}" class="link">Transactions</router-link></li>
        <li @click="toggleMobileNav2"><router-link :to="{name: 'referalHome'}" class="link">Referrals</router-link></li>
      </ul>
    </transition>



  </div>
</template>

<script>
import RouterUtils from "../../util/baseUtils/RouterUtils";
import {mapState} from "vuex";


export default {
  name: "DashBoardSideMenu",
  data()  {
    return {
      mobile: false,
      mobileNav: false,
      windowWidth: false,
    }
  },
  computed:{
    ...mapState({
      loading: state => state.auth.loading,
      auth: state => state.auth,
      userInfo: state => state.auth.userInfo,
    }),
  },
  created() {
    window.addEventListener('resize', this.checkScreen);
    this.checkScreen();
  },
  methods: {
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },
    toggleMobileNav2(){
      this.mobileNav = false;
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 990){
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
    logOut() {
      localStorage.clear()
      RouterUtils.navigateTo(RouterUtils.routes.auth.logInWithEmail.name)
    }
  },
}
</script>

<style scoped>
.outer{
  display: flex;
}
body{
  position: relative;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
}
.logo{
  display: flex;
  margin: 13% auto;
}
.logo p {
  color: #000;
  font-size: 20px;
  margin-left: 10%;
  padding-top: 3px;
}
.logo select{
  font-size: 15px;
  margin-left: 8%;
  padding: 5px 15px;
  border-radius: 5px;
  color: #F04438;
  border: 1px solid #E8E8E8;
}
.sidebar{
  top: 0;
  left: 0;
  height: 100vh;
  width: 235px;
  background-image: url('~@/assets/sidebarimage.svg');
  /*background-color: #FFFFFF;*/
  padding: 6px 20px 37px;
}
.sidebar ul li{
  position: relative;
  height: 50px;
  width: 100%;
  margin: 5px 4px;
  list-style: none;
  line-height: 25px;
}
.sidebar ul li a{
  color: #ffffff;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 12px;
  font-size: 16px;
  padding-top: 3px;
}
.hover:hover{
  background: rgba(255, 255, 255, 0.05);
  /*border: 1px solid rgba(255, 255, 255, 0.05);*/
  backdrop-filter: blur(3.5px);
  padding: 8px;
  border-radius: 4px;
}
.link-img{
  margin-right: 5%;
  width: 12%;
}
.links-name{
  font-size: 16px;
}
.wallets{
  font-size: 15.5px;
}
.feature-card-1 p {
  color: #ffffff;
  font-size: 17px;
}
.feature-card-2 p{
  color: #ffffff;
  font-size: 17px;
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid rgba(255, 255, 255, 0.13);
  border-radius: 3px;
  padding: 2.5px 8px;
  margin-right: 8px;
}
.feature-card-3 img{
  margin-right: 7px;
}
.feature-card-3 p{
  padding-top: 2px;
}
.logout span{
  color: #ffffff;
  font-size: 16px;
  padding-top: 10.5px;
}
.icon {
  position: absolute;
  top: 0;
  right: 24px;
  height: 100%;
  color: #ffffff;
  padding-top: 15px;
}
.icon i {
  cursor: pointer;
  font-size: 30px;
  transition: 0.8s ease all;
}
.icon-active {
  transform: rotate(360deg);
}
.dropdown-nav{
  background-color: #175CD3;
  height: 100vh;
  list-style: none;
}
.logout{
  display: flex;
  justify-content: space-between;
  margin-top: 130%;
  margin-right: 15%;
}

.logout span{
  color: #FFFFFF;
  font-size: 16px;
  padding-top: 10.5px;
}

.icon {
  position: absolute;
  top: 0;
  right: 24px;
  height: 100%;
  color: #000;
  padding-top: 15px;
}

.icon i {
  cursor: pointer;
  font-size: 30px;
  transition: 0.8s ease all;
}

.icon-active {
  transform: rotate(360deg);
}

.links-name-separate{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
}

.links-name-separate span{
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
  margin-top: 0;
}

.lower{
  text-transform: lowercase;
}

.lower::first-letter {

  text-transform: capitalize;

}

@media (max-width: 990px) {
  i{
    font-size: 20px;
    padding-right: 5px;
  }

}

@media (max-width: 550px) {
}

</style>