import router from "../../router/index";


class RouterUtils {
  static routes = {
    base: {
      home: {
        path: '/home',
        name: 'Home'
      },
    },

    auth: {

      signUpEmail: {
        path: '/sign-up-email',
        name: 'signUpEmail'
      },

      verifyEmailAddress: {
        path: '/verify-email-address',
        name: 'verifyEmailAddress'
      },

      accountCreatedSuccessfully: {
        path: '/account-created-successfully',
        name: 'accountCreatedSuccessfully'
      },

      logInWithEmail: {
        path: '/',
        name: 'logInWithEmail'
      },

      loginSuccess: {
        path: '/login-success',
        name: 'loginSuccess'
      },

      forgotPassword: {
        path: '/forgot-password',
        name: 'forgotPassword'
      },

      forgotPasswordVerifyEmail: {
        path: '/forgot-password-verify-email',
        name: 'forgotPasswordVerifyEmail'
      },

      setNewPassword: {
        path: '/set-new-password',
        name: 'setNewPassword'
      },

      signUpSetPassword: {
        path: '/sign-up-set-password',
        name: 'signUpSetPassword'
      },

      passwordResetSuccessful: {
        path: '/password-reset-successful',
        name: 'passwordResetSuccessful'
      }

    },

    dashboard: {
      homeDashBoard: {
        path: '/home-view',
        name: 'homeDashBoard',
      },
      overView: {
        path: '/over-view',
        name: 'overView',
      },
      walletActivities: {
        path: '/wallet-activities',
        name: 'walletActivities',
      },
      transactions: {
        path: '/transactions',
        name: 'transactions',
      },
      virtualAccounts: {
        path: '/virtual-accounts',
        name: 'virtualAccounts',
      },
      walletsManagement: {
        path: '/wallets-management',
        name: 'walletsManagement'
      },
      fundsTransfer: {
        path: '/funds-transfer',
        name: 'fundsTransfer'
      },
      settings: {
        path: '/settings',
        name: 'settings',
      },
      settingsUser: {
        path: '/settings-user',
        name: 'settingsUser',
      },
      aPIKeysAndWebhooks: {
        path: '/aPI-keys-and-webhooks',
        name: 'aPIKeysAndWebhooks',
      },
    }
  };

  static pageTitles = {
    DEFAULT: "Ruby Loan",
    accountingEntry: {
      CREATE: "Assign Entries",
      EDIT: "Edit Entries"
    }
  };
  static navigateTo(targetRoute) {
    router.push({
      name: targetRoute
    })
  }
  static goBack() {
    router.go(-1);
  }
}
export default RouterUtils;

