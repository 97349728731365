<template>
    <div>
      <div class="backdrop"></div>
      <dialog open>
        <div class="alpha">
  
          <div class="first-part">
            <div class="flex">
                <img src="../../../assets/arrow-up-right.png" alt=""/>
                <div class="col">
                    <div>{{selectedItem.accountName}}</div>
                </div>
            </div>
            <div class="flex">
                <div style="margin-right: 9.5px;">₦{{selectedItem.amount}}</div>
                <img src="../../../assets/download.svg" alt=""/>
                <i class='bx bx-x' @click="$emit('close')"></i>
            </div>
          </div>

          <div style="font-size: 10px; font-weight: 700; text-transform: uppercase; margin-top: 16.5px; margin-bottom: 29px;">Transaction Details</div>
          <div class="col small">
            <div class="first-part">
                <p> Transaction ID</p>
                <p class="bold"> {{ selectedItem.id }} </p>
            </div>
            <div class="first-part details">
                <p> Transaction Date</p>
                <p class="bold"> {{ selectedItem.transactionDate }} </p>
            </div>
            <div class="first-part details">
                <p> Transaction Type</p>
                <p class="bold"> {{selectedItem.transactionType}} </p>
            </div>
            <div class="first-part details">
                <p> Amount </p>
                <p class="bold"> ₦{{selectedItem.amount}} </p>
            </div>
            <div class="first-part details">
                <p> Transaction Fee</p>
                <p class="bold"> ₦10 </p>
            </div>
            <div class="first-part details">
                <p> Transaction Rate</p>
                <p class="bold"> %5 </p>
            </div>
            <div class="first-part details">
                <p> Debit Account Number </p>
                <p class="bold"> {{selectedItem.accountNumber}} </p>
            </div>
            <div class="first-part details">
                <p> Debit Account Name</p>
                <p class="bold"> {{selectedItem.accountName}} </p>
            </div>
            <div class="first-part details">
                <p> Transaction Narration</p>
                <p class="bold"> {{selectedItem.narration}} </p>
            </div>
            <div class="first-part details">
                <p> Credit Account Number</p>
                <p class="bold"> {{selectedItem.counterPartyAccountNumber}} </p>
            </div>
            <div class="first-part details">
                <p> Credit Account Name</p>
                <p class="bold"> {{selectedItem.counterPartyAccountName}} </p>
            </div>
            <div class="first-part details">
                <p> Transaction Reference</p>
                <p class="bold">{{selectedItem.paymentReference}}</p>
            </div>
            <div class="first-part details">
                <p> Transaction Status</p>
                <p class="bold"> Successful </p>
            </div>
          </div>
  
  
  
        
  
        </div>
  
      </dialog>
    </div>
  </template>
  
  <script>
  
  

  
  export default {
    name: "TransactionDetailModal",
    emits: ['close'],
    props: {
      selectedItem: {
        type: Object,
        default: null
      }
    },
    methods:{
      createTest(){
  
      },
    },
  }
  </script>
  <style scoped >
  
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  
  dialog {
    position: fixed;
    top: 7vh;
    width: 32rem;
    height: 20rem;
    left: calc(50% - 10rem);
    margin: 0;
    background-color: transparent;
    z-index: 100;
    border: none;
    animation: modal 0.3s ease-out forwards;
  }
  
  .alpha{
    position: relative;
    display: block;
    overflow: hidden;
    width: 450px;
    height: 660px;
    /*height: auto;*/
    padding: 18px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(3, 28, 67, 0.1);
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 34px 0 rgba(3, 28, 67, 0.13);
  }
  
  .first-part{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .bx-x{
    font-size: 25px;
    padding-top: 2px;
  }
  .flex{
    display: flex;
    gap: 20.5px;
    font-size: 14px;
    align-items: center;

  }
  img{
    justify-content: flex-start; 
  }
  .col{
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 14px;
  }
  .details{
    margin-top: 3px;
  }
  .bold{
    font-weight: 700;
  }
  .small{
    font-size: 13px;
  }
  

  
  
  
  
  @keyframes modal {
    from {
      opacity: 0;
      transform: translateY(-50px) scale(0.9);
    }
  
    to {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }
  @media (max-width: 500px) {
    dialog {
      top: 5vh;
      width: 27rem;
      height: 20rem;
      left: calc(50% - 12.4rem);
      right: 30px;
    }
    h3{
      font-size: 20px;
    }
    p{
      font-size: unset;
    }
  }
  </style>