<template>
  <div>
    <forgot-passcode-verify-email/>
  </div>
</template>

<script>
import ForgotPasscodeVerifyEmail from "../../components/auth/signIn/ForgotPasscodeVerifyEmail";
export default {
  name: "ForgotPasswordVerifyEmail",
  components: {ForgotPasscodeVerifyEmail}
}
</script>

<style scoped>

</style>