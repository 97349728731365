<template>
  <div>
    <div class="dropdown">
      <div class="section-alpha" v-if="this.isOpen === true">
          <p>{{ message }}</p>
          <i class='bx bx-chevron-down' v-if="this.isOpen === false"  @click="toggleDropdown"></i>
          <i class='bx bx-chevron-up' v-if="this.isOpen === true"  @click="toggleDropdown"></i>
      </div>

      <div class="section-alpha-2" v-if="this.isOpen === false">
        <p>{{ message }}</p>
        <i class='bx bx-chevron-down' v-if="this.isOpen === false"  @click="toggleDropdown"></i>
        <i class='bx bx-chevron-up' v-if="this.isOpen === true"  @click="toggleDropdown"></i>
      </div>
<!--      <button class="dropdown-toggle">Toggle Dropdown</button>-->
      <div class="dropdown-menu" :class="{ 'show': isOpen }">
        <!-- Dropdown content -->
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropdownMenu",
  data() {
    return {
      isOpen: false
    };
  },
  props: ['message'],
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    }
  },
}
</script>

<style scoped>
.dropdown {
  position: relative;
}

.dropdown-toggle {
  cursor: pointer;
  display: flex;
  justify-content: right;
}

.section-alpha{
  margin-top: 1.5%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  max-width: 57.1%;
  padding: 24px;
  /*gap: 24px;*/
  /*width: 675px;*/
  height: 88px;
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
}

.section-alpha-2{
  margin-top: 1.5%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  max-width: 57.1%;
  padding: 24px;
  /*gap: 24px;*/
  /*width: 675px;*/
  height: 75px;
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 5px 5px 0 0;
}

p{
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;

  color: #1D2939;
}


.bx-chevron-down{
  padding: 6px;
  gap: 6px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  background: #FFFFFF;
  /* Gray/200

  EAECF0
  */
  border: 1px solid #EAECF0;
  border-radius: 5px;
}

.bx-chevron-up{
  padding: 6px;
  gap: 6px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  background: #FFFFFF;
  /* Gray/200

  EAECF0
  */
  border: 1px solid #EAECF0;
  border-radius: 5px;
}

.dropdown-menu {
  display: none;
  position: absolute;
  /*background-color: #f9f9f9;*/
  /*min-width: 160px;*/
  /*box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);*/
  /*padding: 12px 16px;*/
  z-index: 1;
}

.dropdown-menu.show {
  display: block;
}
</style>