class ApiKeyResponse {
    readApiKey = {
        responseCode: null,
        responseMessage: null,
        data: []
    };

    readApiKeyByOrganisationId = {
        apikeyId: null,
        apikeyOrganisationId: null,
        apikeyTestSK: null,
        apikeyTestPK: null,
        apikeyLiveSK: null,
        apikeyLivePK: null,
        apikeyTestWebhook: null,
        apikeyTestCallback: null,
        apikeyLiveWebhook: null,
        apikeyLiveCallback: null,
        apiKeyCreatedAt: null,
        apiKeyUpdatedAt: null,
        apikeyStatus: null,
        apikeyTestHeaderKey: null,
        apikeyTestHeaderValue: null,
        apikeyLiveHeaderKey: null,
        apikeyLiveHeaderValue: null,
        responseCode: null,
        responseMessage: null
    };

    readApiKeyById = {
        apikeyId: null,
        apikeyOrganisationId: null,
        apikeyTestSK: null,
        apikeyTestPK: null,
        apikeyLiveSK: null,
        apikeyLivePK: null,
        apikeyTestCallback: null,
        apikeyLiveCallback: null,
        apiKeyCreatedAt: null,
        apiKeyUpdatedAt: null,
        apikeyStatus: null,
        apikeyTestHeaderKey: null,
        apikeyTestHeaderValue: null,
        apikeyLiveHeaderKey: null,
        apikeyLiveHeaderValue: null,
        responseCode: null,
        responseMessage: null
    };

    updateApiKey = {
        apikeyId: null,
        apikeyOrganisationId: null,
        apikeyTestSK: null,
        apikeyTestPK: null,
        apikeyLiveSK: null,
        apikeyLivePK: null,
        apikeyTestWebhook: null,
        apikeyTestCallback: null,
        apikeyLiveWebhook: null,
        apikeyLiveCallback: null,
        apiKeyCreatedAt: null,
        apiKeyUpdatedAt: null,
        apikeyStatus: null,
        apikeyTestHeaderKey: null,
        apikeyTestHeaderValue: null,
        apikeyLiveHeaderKey: null,
        apikeyLiveHeaderValue: null,
        responseCode: null,
        responseMessage: null
    };


    updateWebhookAndCallback = {
        apikeyId: null,
        apikeyOrganisationId: null,
        apikeyTestSK: null,
        apikeyTestPK: null,
        apikeyLiveSK: null,
        apikeyLivePK: null,
        apikeyTestWebhook: null,
        apikeyTestCallback: null,
        apikeyLiveWebhook: null,
        apikeyLiveCallback: null,
        apiKeyCreatedAt: null,
        apiKeyUpdatedAt: null,
        apikeyStatus: null,
        apikeyTestHeaderKey: null,
        apikeyTestHeaderValue: null,
        apikeyLiveHeaderKey: null,
        apikeyLiveHeaderValue: null,
        responseCode: null,
        responseMessage: null
    };



    data = {
    apikeyId: null,
    apikeyOrganisationId: null,
    apikeyTestSK: null,
    apikeyTestPK: null,
    apikeyLiveSK: null,
    apikeyLivePK: null,
    apiKeyCreatedAt: null,
    apiKeyUpdatedAt: null,
    apikeyStatus: null
    }
}

export default ApiKeyResponse;