import swal from "sweetalert";
import StoreUtils from "@/util/baseUtils/StoreUtils";
import OrganisationRequest from "@/model/request/OrganisationRequest";
import OrganisationService from "@/service/OrganisationService";
import OrganisationResponse from "@/model/response/OrganisationResponse";





export const state = {
    loading: false,
    organisation: null,
    readOrganisationsByCustomerId: new OrganisationResponse().readOrganisationsByCustomerId,
    switchStage: new OrganisationResponse().switchStage,
    readBalances: new OrganisationResponse().readBalances,
    readOrganisationTransactions: new OrganisationResponse().readOrganisationTransactions,
    referralStats: new OrganisationResponse().referralStats,
    readOrganisationById: new OrganisationResponse().readOrganisationById,
};

export const getters = {
    getOrganisation: (state) => {
        return state.organisation;
    },
    getReadOrganisationsByCustomerId: (state) => {
        return state.readOrganisationsByCustomerId;
    },
    getSwitchStage: (state) => {
        return state.switchStage;
    },
    getReadBalances: (state) => {
        return state.readBalances;
    },
    getReadOrganisationTransactions: (state) => {
        return state.readOrganisationTransactions;
    },
    getReferralStats: (state) => {
        return state.referralStats;
    },
    getReadOrganisationById: (state) => {
        return state.readOrganisationById;
    },
};


export const mutations = {
    updateLoading(state, payload){
        state.loading = payload
    },
    updateReadOrganisationsByCustomerId(state, payload){
        state.readOrganisationsByCustomerId = payload
    },
    updateSwitchStage(state, payload){
        state.switchStage = payload
    },
    updateReadBalances(state, payload){
        state.readBalances = payload
    },
    updateReadOrganisationTransactions(state, payload){
        state.readOrganisationTransactions = payload
    },
    updateReferralStats(state, payload){
        state.referralStats = payload
    },
    updateReadOrganisationById(state, payload){
        state.readOrganisationById = payload
    },
};

export const actions = {

    // eslint-disable-next-line no-empty-pattern
    organisationReadOrganisationsByCustomerId({}, payload = new OrganisationRequest().readOrganisationsByCustomerId){
        payload.customerId = localStorage.customerId
        StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, true)
        return OrganisationService.callReadOrganisationsByCustomerIdApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                StoreUtils.commit(StoreUtils.mutations.organisation.updateReadOrganisationsByCustomerId, responseData)
                console.log(responseData)
                localStorage.mid = responseData.data[0].organisationId
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    organisationUpdateOrganisation({}, payload = new OrganisationRequest().updateOrganisation){
        StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, true)
        return OrganisationService.callUpdateOrganisationApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    organisationTransactionEnquiry({}, payload = new OrganisationRequest().transactionEnquiry){
        StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, true)
        return OrganisationService.callTransactionEnquiryApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    organisationBalanceEnquiryOrganisation({}, payload = new OrganisationRequest().balanceEnquiryOrganisation){
        StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, true)
        return OrganisationService.callBalanceEnquiryOrganisationApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    organisationReferralStats({}, payload = {}){
        StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, true)
        return OrganisationService.callReferralStatsApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                StoreUtils.commit(StoreUtils.mutations.organisation.updateReferralStats, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    organisationReadOrganisationById({}, payload = new OrganisationRequest().readOrganisationById){
        StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, true)
        return OrganisationService.callReadOrganisationByIdApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                StoreUtils.commit(StoreUtils.mutations.organisation.updateReadOrganisationById, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    organisationSwitchStage({}, payload = new OrganisationRequest().switchStage){
        StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, true)
        return OrganisationService.callSwitchStageApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                StoreUtils.commit(StoreUtils.mutations.organisation.updateSwitchStage, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    organisationReadBalances({}, payload = new OrganisationRequest().readBalances){
        StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, true)
        return OrganisationService.callReadBalancesApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                StoreUtils.commit(StoreUtils.mutations.organisation.updateReadBalances, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    organisationAddOrganisation({}, payload = new OrganisationRequest().addOrganisation){
        StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, true)
        return OrganisationService.callAddOrganisationApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    organisationReadOrganisationTransactions({}, payload = new OrganisationRequest().readOrganisationTransactions){
        StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, true)
        return OrganisationService.callReadOrganisationTransactionsApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                StoreUtils.commit(StoreUtils.mutations.organisation.updateReadOrganisationTransactions, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.organisation.updateLoading, false)
        })
    },

};