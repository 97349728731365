class AuthenticationResponse {
    login = {
        customerId: null,
        customerFirstName: null,
        customerLastName: null,
        customerEmail: null,
        customerPhone: null,
        customerCountry: null,
        customerCountryCode: null,
        customerOnlineStatus: null,
        customerStatus: null,
        customerCreatedAt: null,
        customerUpdatedAt: null,
        customerLastLoginAt: null,
        token: null,
        organisations: [],
        responseCode: null,
        responseMessage:null
    };



    userInfo = {
        customerId: null,
        customerFirstName: null,
        customerLastName: null,
        customerEmail: null,
        customerPhone: null,
        customerCountry: null,
        customerCountryCode: null,
        customerOnlineStatus: null,
        customerStatus: null,
        customerCreatedAt: null,
        customerUpdatedAt: null,
        customerLastLoginAt: null,
        currentOrganisation: {
            apikeyId: null,
            organisationId: null,
            organisationCustomerId: null,
            organisationAccountNumber: null,
            organisationName: null,
            organisationPhone: null,
            organisationEmail: null,
            organisationStatus: null,
            organisationCreatedAt: null,
            organisationUpdatedAt: null,
            organisationStage: null,
            organisationFtOutwardPrd: null,
            organisationFtInternalPrd: null,
            organisationFtInwardPrd: null,
            organisationReferralCode: null,
            organisationInviteCode: null,
            organisationReferralBonus: null,
            customerOrganisationId: null,
            customerOrganisationOrganisationId: null,
            customerOrganisationCustomerId: null,
            customerOrganisationRole: null,
            customerOrganisationStatus: null,
            customerOrganisationCreatedAt: null,
            customerOrganisationUpdatedAt: null
        },
        organisations: [],
        responseCode: null,
        responseMessage: null
    };

    organisations = {
        organisationId: null,
        organisationCustomerId: null,
        organisationAccountNumber: null,
        organisationName: null,
        organisationPhone: null,
        organisationEmail: null,
        organisationStatus: null,
        organisationCreatedAt: null,
        organisationUpdatedAt: null,
        organisationStage: null,
        organisationFtOutwardPrd: null,
        organisationFtInternalPrd: null,
        organisationFtInwardPrd: null,
        organisationReferralCode: null,
        organisationInviteCode: null,
        organisationTotalCommission: null,
        organisationReferralBonus: null,
        organisationNumberOfVirtualAccount: null,
        organisationNumberOfWallet: null,
        organisationNumberOfWalletDev: null,
        organisationNumberOfVirtualAccountDev: null,
        organisationNumberOfTemporaryVirtualAccount: null,
        organisationNumberOfTemporaryVirtualAccountDev: null,
        organisationTotalKyc: null,
        customerOrganisationId: null,
        customerOrganisationOrganisationId: null,
        customerOrganisationCustomerId: null,
        customerOrganisationRole: null,
        customerOrganisationStatus: null,
        customerOrganisationCreatedAt: null,
        customerOrganisationUpdatedAt: null
    }
}

export default AuthenticationResponse