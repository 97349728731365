<template>
  <div class="alpha">
    <dash-board-side-menu/>
    <dash-content>

      <div class="section-4">
        <p class="section-4-header">Settings</p>
      </div>

      <div class="section-2">
        <p @click="profile" >Profile</p>
        <p  class="active" >API Keys And Webhooks</p>
        <p @click="user">User</p>
      </div>


      <div class="second-part">
        <p class="second-text-1">API Keys And Webhooks</p>
        <form @submit.prevent="update">

<!--          <div class="input-form-2">-->
          <label>Live Secret Key</label>
            <input type="text"  v-model="apikeyLiveSK" placeholder="Live Secret Key" class="input-form-1"  disabled/>
          <label>Live Public Key</label>
            <input type="text" v-model="apikeyLivePK" placeholder="Live Public Key" class="input-form-1"  disabled />
<!--          </div>-->


<!--          <div class="input-form-2">-->
          <label>Live Callback URL</label>
            <input type="text" v-model="apikeyLiveCallback"  placeholder="Live Callback URL" class="input-form-1"  />
<!--          </div>-->

<!--          <div class="input-form-2">-->
          <label>Live Authorization Header</label>
            <input type="text" v-model="apikeyLiveHeaderKey"   placeholder="Live Authorization Header" class="input-form-1"  />

          <!--          <div class="input-form-2">-->
          <label>Live Authorization Value</label>
          <input type="text" v-model="apikeyLiveHeaderValue"   placeholder="Live Authorization Value" class="input-form-1"  />

          <label>Phone Number</label>
            <input type="number" v-model="customerPhone" placeholder="Phone Number" class="input-form-1"   disabled/>
<!--          </div>-->



          <br/>
          <div class="separate">
            <base-button :loading="loading">Update</base-button>
          </div>

        </form>
      </div>


    </dash-content>
  </div>
</template>

<script>
import RouterUtils from "@/util/baseUtils/RouterUtils";
import DashBoardSideMenu from "@/components/dashboard/DashBoardSideMenu.vue";
import DashContent from "@/components/baseComponents/dash/DashContent.vue";
import BaseButton from "@/components/baseComponents/buttons/BaseButton.vue";
import {mapState} from "vuex";
import StoreUtils from "@/util/baseUtils/StoreUtils";
import ApiKeyRequest from "@/model/request/ApiKeyRequest";


export default {
  name: "DashBoardAPIKeysAndWebhooksView",
  components: {BaseButton, DashContent, DashBoardSideMenu},
  data() {
    return {
      model: new ApiKeyRequest().readApiKeyByOrganisationId,
      apikeyLiveSK: "",
      apikeyLivePK: "",
      apikeyLiveCallback: "",
      apikeyLiveHeaderKey: "",
      apikeyLiveHeaderValue: "",
      customerPhone: ""
    };
  },
  computed:{
    ...mapState({
      loading: state => state.apiKey.loading,
      auth: state => state.auth,
      userInfo: state => state.auth.userInfo,
      readApiKeyByOrganisationId: state => state.apiKey.readApiKeyByOrganisationId,
    }),
  },
  methods:{
    user(){
      RouterUtils.navigateTo(RouterUtils.routes.dashboard.settingsUser.name)
    },
    profile(){
      RouterUtils.navigateTo(RouterUtils.routes.dashboard.settings.name)
    },
    assign(){
      this.apikeyLiveSK = this.readApiKeyByOrganisationId.apikeyLiveSK
      this.apikeyLivePK = this.readApiKeyByOrganisationId.apikeyLivePK
      this.apikeyLiveCallback = this.readApiKeyByOrganisationId.apikeyLiveCallback
      this.apikeyLiveHeaderKey = this.readApiKeyByOrganisationId.apikeyLiveHeaderKey
      this.apikeyLiveHeaderValue = this.readApiKeyByOrganisationId.apikeyLiveHeaderValue
      this.customerPhone = this.userInfo.customerPhone
    },
    async update() {
     await StoreUtils.dispatch(StoreUtils.actions.apiKey.apiKeyUpdateWebhookAndCallback, {
       apikeyId: this.userInfo.currentOrganisation.organisationId,
       apikeyOrganisationId: this.userInfo.currentOrganisation.organisationId,
       apikeyTestWebhook: this.readApiKeyByOrganisationId.apikeyTestWebhook,
       apikeyTestCallback: this.readApiKeyByOrganisationId.apikeyTestCallback,
       apikeyLiveWebhook: this.readApiKeyByOrganisationId.apikeyLiveWebhook,
       apikeyLiveCallback: this.apikeyLiveCallback,
       apikeyTestHeaderKey: this.readApiKeyByOrganisationId.apikeyTestHeaderKey,
       apikeyTestHeaderValue: this.readApiKeyByOrganisationId.apikeyTestHeaderValue,
       apikeyLiveHeaderKey: this.apikeyLiveHeaderKey,
       apikeyLiveHeaderValue: this.apikeyLiveHeaderValue
      })
    },
  },
  created() {
    this.assign()
  },
  async mounted() {
    await StoreUtils.dispatch(StoreUtils.actions.apiKey.apiKeyReadApiKeyByOrganisationId, {
      apikeyOrganisationId: this.userInfo.currentOrganisation.organisationId
    })
    if (this.readApiKeyByOrganisationId.length > 0) this.model = this.readApiKeyByOrganisationId
    this.assign()
  },

}
</script>

<style scoped>
.alpha{
  display: flex;
}
.section-1{
  display: flex;
  justify-content: right;
  align-content: center;
  align-items: center;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  padding-right: 3%;
  background-color: #FFFFFF;
}
.vl{
  border-left: 1.5px solid #E7E8F0;
  height: 35px;
  margin-left: 1%;
  margin-right: 1%;
}
.profile-name{
  padding-left: 1%;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #58596C;
}

.section-4-header{
  padding-left: 1.5%;
  margin-top: 1.5%;
  font-weight: 700;
  font-size: 19px;
  line-height: 25px;
  color: #353542;
}

.section-2{
  padding-left: 3%;
  margin-left: 3%;
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 470px;
  height: 46px;
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 8px;
  color: #667085;
}

.section-2 p{
  margin-right: 11%;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.active{
  display: flex;
  align-content: center;
  align-items: center;
  color: #0765FF;
  height: 34px;
  padding-right: 30px;
  padding-left: 30px;
  background: #ECF2FF;
  border-radius: 6px;
}

.second-part{
  padding: 24px;
  padding-right: 45px;
  gap: 15px;
  width: 680px;
  height: 640px;
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 8px;
  margin-left: 4%;
  margin-top: 1.5%;
  margin-bottom: 2%;
}

.second-text-1{
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
  /*margin-top: 1%;*/
  margin-bottom: 1%;
}

.input-form-2{
  display: flex;
  justify-content: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

.input-form-1{
  order: 1;
  width: 100%;
  padding: 12px 10px;
  margin: 8px 0;
  display: inline-block;
  box-sizing: border-box;
  margin-bottom: 2%;
  margin-right: 2%;
  margin-left: 2%;
}

input {
  box-sizing: border-box;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  -webkit-transition: 0.3s;
  padding-top: 12px;
  padding-bottom: 12px;
  transition: 0.3s;
  outline: none;
  color: #667085;
  letter-spacing: 0.5px;
}

input:focus {
  border: 1px solid #24405A;
}


select {
  box-sizing: border-box;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  -webkit-transition: 0.3s;
  padding-top: 12px;
  padding-bottom: 12px;
  transition: 0.3s;
  outline: none;
  color: #667085;
  letter-spacing: 0.5px;
}

select:focus {
  border: 1px solid #24405A;
}

.separate{
  margin-top: 2%;
  margin-left: 5%;
}

label{
  color: #667085;
  font-size: 15px;
}

</style>