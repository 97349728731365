<template>
  <div class="alpha">
    <div class="image">
      <img src="@/assets/successcheck.svg" alt="fether">
    </div>

    <div class="text-area">
      <h3>Login success🥳</h3>
      <p>You have successfully Logged in to your<br/>
        Afropay account</p>
    </div>

    <div class="submit">
      <input type="submit" class="submit-btn"  @click="signIn"  value="Proceed to Dashboard"/>
    </div>

  </div>
</template>

<script>
import RouterUtils from "../../../util/baseUtils/RouterUtils";

export default {
  name: "SignInSuccess",
  methods:{
    signIn(){
      RouterUtils.navigateTo(RouterUtils.routes.dashboard.homeDashBoard.name)
    },
  },
}
</script>

<style scoped>
.alpha{
  margin-top: 13%;
}


.text-area h3{
  padding-bottom: 0;
  margin-bottom: 10px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #667085;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.text-area p{
  padding-top: 2px;
  margin-top: 2px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #667085;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.text-area{
  margin-bottom: 1%;
}
.image{
  display: flex;
  justify-content: center;
}

.submit{
  display: flex;
  justify-content: center;
  margin-top: 1.5%;
}

.submit-btn{
  background-color: #1570EF;
  border-color: #1570EF;
  border-bottom-color: transparent;
  border-right-color: transparent;
  color: #ffffff;
  padding: 12px 8% 12px 8%;
  border-radius: 5px;
}

.submit-btn:hover{
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.7);
}
</style>