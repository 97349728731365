<template>
  <div>
    <div class="backdrop"></div>
    <dialog open>
      <div class="alpha">

        <div class="first-part">
<!--          <img src="@/assets/wallet-icon-modal.svg" alt="wallet-icon-modal"/>-->
          <p class="first-part-text-1">{{selectedItem.accountName}}</p>
          <div class="first-part-separate">
            <p class="first-part-text-2">{{selectedItem.accountStatus}}</p>
            <i class='bx bx-x' @click="$emit('close')"></i>
          </div>

        </div>

        <div class="second-part">
          <p class="second-text-1">TRANSACTION  DETAILS</p>

          <div class="second-part-separate">
            <p class="second-text-2">Account ID</p>
            <p class="second-text-3">{{selectedItem.accountId}}</p>
          </div>

          <div class="second-part-separate">
            <p class="second-text-2">Account Customer ID</p>
            <p class="second-text-3">{{selectedItem.accountCustomerId}}</p>
          </div>

          <div class="second-part-separate">
            <p class="second-text-2">Account Number</p>
            <p class="second-text-3">{{selectedItem.accountNumber}}</p>
          </div>

          <div class="second-part-separate">
            <p class="second-text-2">Account Name</p>
            <p class="second-text-3">{{selectedItem.accountName}}</p>
          </div>

          <div class="second-part-separate">
            <p class="second-text-2">Account Currency</p>
            <p class="second-text-3">{{selectedItem.accountCurrency}}</p>
          </div>

          <div class="second-part-separate">
            <p class="second-text-2">Account Balance</p>
            <p class="second-text-3">{{selectedItem.accountBalance}}</p>
          </div>

          <div class="second-part-separate">
            <p class="second-text-2">Account Status</p>
            <p class="second-text-3">{{selectedItem.accountStatus}}</p>
          </div>

          <div class="second-part-separate">
            <p class="second-text-2">Account Ledger Balance</p>
            <p class="second-text-3">{{selectedItem.accountLedgerBalance}}</p>
          </div>

          <div class="second-part-separate">
            <p class="second-text-2">Account Allow Credit</p>
            <p class="second-text-3">{{selectedItem.accountAllowCredit}}</p>
          </div>

          <div class="second-part-separate">
            <p class="second-text-2">Account Allow Debit</p>
            <p class="second-text-3">{{selectedItem.accountAllowDebit}}</p>
          </div>

          <div class="second-part-separate">
            <p class="second-text-2">Account Monthly Debit</p>
            <p class="second-text-3">{{selectedItem.accountMonthlyDebit}}</p>
          </div>

          <div class="second-part-separate">
            <p class="second-text-2">Account Monthly Credit</p>
            <p class="second-text-3">{{selectedItem.accountMonthlyCredit}}</p>
          </div>

          <div class="second-part-separate">
            <p class="second-text-2">Account Daily Debit</p>
            <p class="second-text-3">{{selectedItem.accountDailyDebit}}</p>
          </div>

          <div class="second-part-separate">
            <p class="second-text-2">Account Daily Credit</p>
            <p class="second-text-3">{{selectedItem.accountDailyCredit}}</p>
          </div>

          <div class="second-part-separate">
            <p class="second-text-2">Account Available Balance</p>
            <p class="second-text-3">{{selectedItem.accountAvailableBalance}}</p>
          </div>

          <div class="second-part-separate">
            <p class="second-text-2">Account Maximum Balance</p>
            <p class="second-text-3">{{selectedItem.accountMaximumBalance}}</p>
          </div>

          <div class="second-part-separate">
            <p class="second-text-2">Account Minimum Balance</p>
            <p class="second-text-3">{{selectedItem.accountMinimumBalance}}</p>
          </div>

<!--          <div class="second-part-separate">-->
<!--            <p class="second-text-2">Show Details</p>-->
<!--            <p class="second-text-3">{{selectedItem.accountAllowDebit}}</p>-->
<!--          </div>-->

        </div>

        <div class="separate">
          <button class="btn" @click="handleClick">Block Wallet</button>
          <button class="btn" @click="handleClick2">Lien Wallet</button>
        </div>


      </div>

    </dialog>
  </div>
</template>

<script>

import {mapState} from "vuex";
// import StoreUtils from "@/util/baseUtils/StoreUtils";

export default {
  name: "virtualAccountDetailsModal",
  emits: ['close', 'open', 'open2'],
  data() {
    return {

    };
  },
  props: {
    selectedItem: {
      type: Object,
      default: null
    }
  },
  computed:{
    ...mapState({
      loading: state => state.wallet.loading,
      auth: state => state.auth,
      userInfo: state => state.auth.userInfo,
    }),

  },
  methods:{
    handleClick() {
      this.$emit('open');
      this.$emit('close');
    },
    handleClick2() {
      this.$emit('open2');
      this.$emit('close');
    },
  },
  // async mounted() {
  //   await StoreUtils.dispatch(StoreUtils.actions.wallet.walletReadWalletActivityByAccountNumber, {
  //     accountNumber: this.selectedItem.accountNumber,
  //     page:1
  //   })
  // },
}
</script>

<style scoped >

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);
}


dialog {
  position: fixed;
  top: 1vh;
  width: 32rem;
  height: 20rem;
  left: calc(50% - 14rem);
  margin: 0;
  background-color: transparent;
  z-index: 100;
  border: none;
  animation: modal 0.3s ease-out forwards;
}

.alpha{
  position: relative;
  display: block;
  overflow: hidden;
  overflow-y: scroll;
  width: 526px;
  height: 800px;
  /*height: auto;*/
  padding: 24px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(3, 28, 67, 0.1);
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 34px 0 rgba(3, 28, 67, 0.13);
}

.first-part{
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.bx-x{
  font-size: 25px;
  padding-top: 2px;
  color: #101828;
}

.separate{
  display: flex;
  align-items: center;
  align-content: center;
}

.first-part-separate{
  display: flex;
  align-items: center;
  align-content: center;
}

.first-part-text-1{
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #101828;
}

.first-part-text-2{
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #039855;
  padding: 4px 12px;
  gap: 10px;
  width: 66px;
  height: 23px;
  background: #F6FEF9;
  border-radius: 50px;
}

.btn{
  margin-right: 1%;
  margin-left: 1%;
  align-items: center;
  /*padding: 10px 28px;*/
  gap: 8px;
  color: #FFFFFF;
  width: 570px;
  height: 48px;
  background: #1570EF;
  border: 1px solid #1570EF;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  margin-top: 2%;
}

.second-text-1{
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: #101828;
  margin-top: 2%;
  margin-bottom: 4%;
}

.second-part-separate{
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-bottom: 5%;
}

.second-text-2{
  font-weight: 200;
  font-size: 14px;
  line-height: 16px;
  color: #1D2939;
}

.second-text-3{
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  color: #1D2939;
}


@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
@media (max-width: 500px) {
  dialog {
    top: 5vh;
    width: 27rem;
    height: 20rem;
    left: calc(50% - 12.4rem);
    right: 30px;
  }
  h3{
    font-size: 20px;
  }
  p{
    font-size: unset;
  }
}
</style>