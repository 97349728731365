import swal from "sweetalert";
import StoreUtils from "@/util/baseUtils/StoreUtils";
import WalletService from "@/service/WalletService";
import WalletRequest from "@/model/request/WalletRequest";
import WalletResponse from "@/model/response/WalletResponse";



export const state = {
    loading: false,
    wallet: new WalletResponse().create,
    wallets: new WalletResponse().read,
    balanceEnquiry: new WalletResponse().balanceEnquiry,
    retrieve: new WalletResponse().retrieve,
    readLien: new WalletResponse().readLien,
    readWalletActivityByAccountNumber: new WalletResponse().readWalletActivityByAccountNumber,
    readWalletActivity: new WalletResponse().readWalletActivity,
};

export const getters = {
    getWallet: (state) => {
        return state.wallet;
    },
    getWallets: (state) => {
        return state.wallets;
    },
    getBalanceEnquiry: (state) => {
        return state.balanceEnquiry;
    },
    getRetrieve: (state) => {
        return state.retrieve;
    },
    getReadLien: (state) => {
        return state.readLien;
    },
    getReadWalletActivityByAccountNumber: (state) => {
        return state.readWalletActivityByAccountNumber;
    },
    getReadWalletActivity: (state) => {
        return state.readWalletActivity;
    },
};


export const mutations = {
    updateLoading(state, payload){
        state.loading = payload
    },
    updateWallet(state, payload){
        state.wallet = payload
    },
    updateWallets(state, payload){
        state.wallets = payload
    },
    updateBalanceEnquiry(state, payload){
        state.balanceEnquiry = payload
    },
    updateRetrieve(state, payload){
        state.retrieve = payload
    },
    updateReadLien(state, payload){
        state.readLien = payload
    },
    updateReadWalletActivityByAccountNumber(state, payload){
        state.readWalletActivityByAccountNumber = payload
    },
    updateReadWalletActivity(state, payload){
        state.readWalletActivity = payload
    },
};

export const actions = {
    // eslint-disable-next-line no-empty-pattern
    walletCreate({}, payload = new WalletRequest().create){
        StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, true)
        return WalletService.callCreateApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
                StoreUtils.commit(StoreUtils.mutations.wallet.updateWallet, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    walletBalanceEnquiry({}, payload = new WalletRequest().balanceEnquiry){
        StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, true)
        return WalletService.callBalanceEnquiryApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
                StoreUtils.commit(StoreUtils.mutations.wallet.updateBalanceEnquiry, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    walletRetrieve({}, payload = new WalletRequest().retrieve){
        StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, true)
        return WalletService.callRetrieveApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
                StoreUtils.commit(StoreUtils.mutations.wallet.updateRetrieve, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, false)
        })
    },

    //eslint-disable-next-line no-empty-pattern
    // walletRead({}, payload = {}){
    //     StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, true)
    //     return WalletService.callReadApi(payload).then(response=>{
    //         StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, false)
    //         let responseData = response.data
    //         if (responseData.responseCode === '00'){
    //             StoreUtils.commit(StoreUtils.mutations.wallet.updateWallets, responseData)
    //         }else{
    //             swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
    //         }
    //     }).catch(error=>{
    //         console.log(error)
    //         StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, false)
    //     })
    // },

    // eslint-disable-next-line no-empty-pattern
    walletRead({}, payload = new WalletRequest().read){
        StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, true)
        return WalletService.callReadApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                // swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
                StoreUtils.commit(StoreUtils.mutations.wallet.updateWallets, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    walletBlockAndUnblock({}, payload = new WalletRequest().walletBlockAndUnblock){
        StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, true)
        return WalletService.callWalletBlockAndUnblockApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    walletAmountBlockAndUnblock({}, payload = new WalletRequest().amountBlockAndUnblock){
        StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, true)
        return WalletService.callAmountBlockAndUnblockApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    walletReadLien({}, payload = new WalletRequest().readLien){
        StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, true)
        return WalletService.callReadLienApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
                StoreUtils.commit(StoreUtils.mutations.wallet.updateReadLien, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    walletReadWalletActivityByAccountNumber({}, payload = new WalletRequest().readWalletActivityByAccountNumber){
        StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, true)
        return WalletService.callReadWalletActivityByAccountNumberApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                StoreUtils.commit(StoreUtils.mutations.wallet.updateReadWalletActivityByAccountNumber, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, false)
        })
    },

    //eslint-disable-next-line no-empty-pattern
    walletReadWalletActivity({}, payload = {}){
        StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, true)
        return WalletService.callReadWalletActivityApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === '00'){
                StoreUtils.commit(StoreUtils.mutations.wallet.updateReadWalletActivity, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.wallet.updateLoading, false)
        })
    },

};