class CustomerResponse {

    updateCustomer = {
        customerId: null,
        customerFirstName: null,
        customerLastName: null,
        customerEmail: null,
        customerPhone: null,
        customerAddress: null,
        customerDob: null,
        customerCountry: null,
        customerCountryCode: null,
        customerState: null,
        customerLga: null,
        customerOtpUpdatedAt: null,
        customerOnlineStatus: null,
        customerStatus: null,
        customerCreatedAt: null,
        customerUpdatedAt: null,
        responseCode: null,
        responseMessage: null
    };
    readCustomerById = {
        customerId: null,
        customerFirstName: null,
        customerLastName: null,
        customerEmail: null,
        customerPhone: null,
        customerAddress: null,
        customerDob: null,
        customerCountry: null,
        customerCountryCode: null,
        customerState: null,
        customerLga: null,
        customerOtpUpdatedAt: null,
        customerOnlineStatus: null,
        customerStatus: null,
        customerCreatedAt: null,
        customerUpdatedAt: null,
        customerLastLoginAt: null,
        responseCode: null,
        responseMessage: null
    };

    readByEmail = {
        customerId: null,
        customerFirstName: null,
        customerLastName: null,
        customerEmail: null,
        customerPhone: null,
        customerAddress: null,
        customerDob: null,
        customerCountry: null,
        customerCountryCode: null,
        customerState: null,
        customerLga: null,
        customerOtpUpdatedAt: null,
        customerOnlineStatus: null,
        customerStatus: null,
        customerCreatedAt: null,
        customerUpdatedAt: null,
        customerLastLoginAt: null,
        responseCode: null,
        responseMessage: null
    };

    readByPhone = {
        customerId: null,
        customerFirstName: null,
        customerLastName: null,
        customerEmail: null,
        customerPhone: null,
        customerAddress: null,
        customerDob: null,
        customerCountry: null,
        customerCountryCode: null,
        customerState: null,
        customerLga: null,
        customerOtpUpdatedAt: null,
        customerOnlineStatus: null,
        customerStatus: null,
        customerCreatedAt: null,
        customerUpdatedAt: null,
        customerLastLoginAt: null,
        responseCode: null,
        responseMessage: null
    };

    readInviteByOrganisationId = {
        responseCode: null,
        responseMessage: null,
        data: []
    };

    data = {
        inviteId: null,
        inviteCustomerEmail: null,
        inviteCustomerRole: null,
        inviteOrganisationId: null,
        inviteLink: null,
        inviteCustomerSenderId: null,
        inviteStatus: null,
        inviteCreatedAt: null,
        inviteUpdatedAt: null
    }

}

export default CustomerResponse;