<template>
  <div>
    <sign-with-email/>
  </div>
</template>

<script>
import SignWithEmail from "../../components/auth/signIn/SignWithEmail";
export default {
  name: "LoginWithEmail",
  components: {SignWithEmail}
}
</script>

<style scoped>

</style>