<template>
  <div>
    <dashboard-layout>
      <dash-board-wallets-management-view/>
    </dashboard-layout>
  </div>
</template>

<script>
import DashBoardWalletsManagementView from "@/components/dashboard/DashBoardWalletsManagementView.vue";
import DashboardLayout from "@/layout/DashboardLayout.vue";

export default {
  name: "WalletsManagement",
  components: {DashboardLayout, DashBoardWalletsManagementView}
}
</script>

<style scoped>

</style>