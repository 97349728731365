<template>
  <div class="alpha">

    <div class="image">
      <img src="@/assets/afropaylogo.svg" alt="inter-switch-logo" >
    </div>

    <div class="text-area">
      <h3>Log in to your account</h3>
      <p>Welcome back! Please enter your details.</p>
    </div>

    <div class="form-alpha">
      <form @submit.prevent="signIn">

        <div class="input-form-2">
          <input type="email" v-model="model.username"  placeholder="Enter your email" class="input-form-1" required="required" />
        </div>

        <div class="has-addons">
          <input v-if="showPassword2" v-model="model.password" required="required"  type="text" class="input-form-1 password"   placeholder="Confirm password"   />
          <input v-else type="password" v-model="model.password" required="required" class="input-form-1  password"   placeholder="Confirm password"   >
          <div class="space">
            <i class="fas" :class="{ 'fa-eye-slash': showPassword2, 'fa-eye': !showPassword2 }" @click="toggleShow2"></i>
          </div>
        </div>

        <div class="select">
          <div class="checkbox-part">
            <input type="checkbox"  class="checkbox"/>
            <p class="checkbox-text">Remember for 30 days.</p>
          </div>

          <div class="forgot" @click="forgotPassword">
            <p>Forgot password</p>
          </div>
        </div>


        <div class="submit">
          <base-button :loading="loading">Login</base-button>
        </div>

      </form>
    </div>

    <div class="last-text">
      <p>Don’t have an account? <span @click="signUp">Sign up</span></p>
    </div>

  </div>
</template>

<script>
import RouterUtils from "../../../util/baseUtils/RouterUtils";
import AuthenticationRequest from "../../../model/request/AuthenticationRequest";
import StoreUtils from "../../../util/baseUtils/StoreUtils";
import {mapState} from "vuex";
import BaseButton from "@/components/baseComponents/buttons/BaseButton.vue";

export default {
  name: "SignWithEmail",
  components: {BaseButton},
  data(){
    return {
      model: new AuthenticationRequest().login,
      showPassword2: false,
      status: "pending"
    }
  },
  computed:{
    ...mapState({
      loading: state => state.auth.loading,
      auth: state => state.auth,
    }),
    buttonLabel2() {
      return (this.showPassword2) ? "Hide" : "Show";
    }
  },
  methods:{
    toggleShow2() {
      this.showPassword2 = !this.showPassword2;
    },

    signUp(){
      RouterUtils.navigateTo(RouterUtils.routes.auth.signUpEmail.name)
    },

    signIn(){
      StoreUtils.dispatch(StoreUtils.actions.auth.login, this.model)
    },

    forgotPassword(){
      RouterUtils.navigateTo(RouterUtils.routes.auth.forgotPassword.name)
    },
  },
}
</script>

<style scoped>
.alpha{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 11%;
}


.text-area h3{
  padding-bottom: 0;
  margin-bottom: 0;
  text-align: center;
  color: #101828;
  font-weight: 600;
  font-size: 21px;
  line-height: 38px;
}

.text-area p{
  padding-top: 2px;
  margin-top: 2px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #667085;
  margin-bottom: 3%;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.text-area{
  margin-bottom: 1%;
}

.image{
  display: flex;
  justify-content: center;
  margin-bottom: 0.8%;
}
.form-alpha{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.input-form-2{
  display: flex;
  justify-content: center;
}

.input-form-1{
  order: 1;
  width: 100%;
  padding: 13px 20px;
  margin: 8px 0;
  display: inline-block;
  box-sizing: border-box;
}

input {
  box-sizing: border-box;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  -webkit-transition: 0.3s;
  padding-top: 12px;
  padding-bottom: 12px;
  transition: 0.3s;
  outline: none;
  color: #667085;
  letter-spacing: 0.5px;
}

input:focus {
  border: 1px solid #24405A;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.has-addons{
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 5%;
}
button{
  background-color: transparent;
}

.fas{
  font-size: 13px;
  margin-top: 10%;
}

.space{
  padding-top: 10.4px;
  padding-bottom: 11px;
  padding-right: 10px;
  border: 1px solid #d0d5dd;
  border-left-style: none;
  border-radius: 0 8px 8px 0;
  font-size: 1rem;
}

.input-form-1.password {
  border-right-style: none;
  border-radius: 8px 0 0 8px;
}

.checkbox-part{
  display: flex;
  justify-content: center;
  margin-top: 4%;
  margin-bottom: 4%;
}
.checkbox{
  margin-right: 10px;
}
.checkbox-text{
  font-size: 15px;
  color: #667085;
}

.submit{

}


.last-text{
  margin-top: 1.7%;
  text-align: center;
  color: #667085;
}

span{
  color: #33536F;
}


.select{
  display: flex;
  margin-top: 3%;
  margin-bottom: 3%;
}

.forgot{
  margin-top: 4%;
  margin-bottom: 4%;
}

.forgot p{
  color: #33536F;
  margin-left: 50px;
}


</style>