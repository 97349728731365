import { render, staticRenderFns } from "./SignUpSetPassword.vue?vue&type=template&id=23d404d4&scoped=true&"
import script from "./SignUpSetPassword.vue?vue&type=script&lang=js&"
export * from "./SignUpSetPassword.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23d404d4",
  null
  
)

export default component.exports