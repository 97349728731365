<template>
  <div>
    <dashboard-layout>
      <dash-board-settings-view/>
    </dashboard-layout>
  </div>
</template>

<script>
import DashBoardSettingsView from "@/components/dashboard/DashBoardSettingsView.vue";
import DashboardLayout from "@/layout/DashboardLayout.vue";

export default {
  name: "Settings",
  components: {DashboardLayout, DashBoardSettingsView}
}
</script>

<style scoped>

</style>