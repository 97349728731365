class OrganisationRequest {

    updateOrganisation = {
        organisationId: null,
        organisationAddress: null,
        organisationName: null,
        organisationLogo: null,
        organisationRegistrationNo: null,
        organisationRegistrationDate: null,
        organisationCity: null,
        organisationState: null,
        organisationPhone: null,
        organisationMemorandumAndArticleOfAssociation: null,
        organisationEmail: null,
        organisationType: null,
        organisationCustomerId: null,
        organisationAccountNumber: null,
        organisationStatus: null,
        organisationWebsite: null,
        organisationCountry: null,
        organisationIndustryType: null,
        organisationFormCac: null,
        organisationCertificateIncorpation: null,
        organisationScumlCertificate: null,
        organisationTin: null,
        organisationDirectorName:null,
        organisationDirectorDob: null,
        organisationDirectorIdCard: null,
        organisationDirectorIdCardType: null,
        organisationDirectorName2: null,
        organisationDirectorBvn2: null,
        organisationDirectorDob2: null,
        organisationDirectorIdCard2: null,
        organisationDirectorIdCardType2: null,
        organisationDirectorBvn: null,
    };

    transactionEnquiry = {
        bankCode: null,
        source: null,
        customerId: null,
        startDate: null,
        endDate: null,
        queryType: null
    };

    balanceEnquiryOrganisation = {
        accountBankCode: null,
        accountNumber: null,
    };

    readOrganisationById = {
        organisationId: null,
    };

    switchStage = {
        organisationStage: null,
    };

    readBalances = {
        requestType: null,
    };

    readOrganisationsByCustomerId = {
        customerId: null,
    };

    addOrganisation = {
        customerId: null,
        customerBusinessName: null,
        customerCountry: null,
        customerCountryCode: null,
        customerEmail: null,
        customerPhone: null,
    };

    readOrganisationTransactions = {
        accountNumber: null,
        page: null,
    };
}

export default OrganisationRequest;