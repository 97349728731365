import swal from "sweetalert";
import StoreUtils from "@/util/baseUtils/StoreUtils";
import ApiKeyService from "@/service/ApiKeyService";
import ApiKeyRequest from "@/model/request/ApiKeyRequest";
import ApiKeyResponse from "@/model/response/ApiKeyResponse";




export const state = {
    loading: false,
    apiKey: null,
    apiKeys: new ApiKeyResponse().readApiKey,
    readApiKeyByOrganisationId: new ApiKeyResponse().readApiKeyByOrganisationId,
    readApiKeyById: new ApiKeyResponse().readApiKeyById,
    updateApiKey: new ApiKeyResponse().updateApiKey,
    updateWebhookAndCallback: new ApiKeyResponse().updateWebhookAndCallback,
};

export const getters = {
    getApiKey: (state) => {
        return state.apiKey;
    },
    getApiKeys: (state) => {
        return state.apiKeys;
    },
    getReadApiKeyByOrganisationId: (state) => {
        return state.readApiKeyByOrganisationId;
    },
    getReadApiKeyById: (state) => {
        return state.readApiKeyById;
    },
    getUpdateApiKey: (state) => {
        return state.updateApiKey;
    },
    getUpdateWebhookAndCallback: (state) => {
        return state.updateWebhookAndCallback;
    },
};


export const mutations = {
    updateLoading(state, payload){
        state.loading = payload
    },
    updateApiKey(state, payload){
        state.apiKey = payload
    },
    updateApiKeys(state, payload){
        state.apiKeys = payload
    },
    updateReadApiKeyByOrganisationId(state, payload){
        state.readApiKeyByOrganisationId = payload
    },
    updateReadApiKeyById(state, payload){
        state.readApiKeyById = payload
    },
    updateUpdateApiKey(state, payload){
        state.updateApiKey = payload
    },
    updateUpdateWebhookAndCallback(state, payload){
        state.updateWebhookAndCallback = payload
    },
};

export const actions = {

    //eslint-disable-next-line no-empty-pattern
    apiKeyRead({}, payload = {}){
        StoreUtils.commit(StoreUtils.mutations.apiKey.updateLoading, true)
        return ApiKeyService.callReadApiKeyApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.apiKey.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === '00'){
                StoreUtils.commit(StoreUtils.mutations.apiKey.updateApiKeys, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.apiKey.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    apiKeyReadApiKeyByOrganisationId({}, payload = new ApiKeyRequest().readApiKeyByOrganisationId){
        StoreUtils.commit(StoreUtils.mutations.apiKey.updateLoading, true)
        return ApiKeyService.callReadApiKeyByOrganisationIdApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.apiKey.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                // swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
                StoreUtils.commit(StoreUtils.mutations.apiKey.updateReadApiKeyByOrganisationId, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.apiKey.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    apiKeyReadApiKeyById({}, payload = new ApiKeyRequest().readApiKeyById){
        StoreUtils.commit(StoreUtils.mutations.apiKey.updateLoading, true)
        return ApiKeyService.callReadApiKeyByIdApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.apiKey.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
                StoreUtils.commit(StoreUtils.mutations.apiKey.updateReadApiKeyById, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.apiKey.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    apiKeyUpdateApiKey({}, payload = new ApiKeyRequest().updateApiKey){
        StoreUtils.commit(StoreUtils.mutations.apiKey.updateLoading, true)
        return ApiKeyService.callUpdateApiKeyApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.apiKey.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
                StoreUtils.commit(StoreUtils.mutations.apiKey.updateUpdateApiKey, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.apiKey.updateLoading, false)
        })
    },


    // eslint-disable-next-line no-empty-pattern
    apiKeyGenerateApiKey({}, payload = new ApiKeyRequest().generateApiKey){
        StoreUtils.commit(StoreUtils.mutations.apiKey.updateLoading, true)
        return ApiKeyService.callGenerateApiKeyApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.apiKey.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
                // StoreUtils.commit(StoreUtils.mutations.apiKey.updateUpdateApiKey, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.apiKey.updateLoading, false)
        })
    },




    // eslint-disable-next-line no-empty-pattern
    apiKeyUpdateWebhookAndCallback({}, payload = new ApiKeyRequest().updateWebhookAndCallback){
        StoreUtils.commit(StoreUtils.mutations.apiKey.updateLoading, true)
        return ApiKeyService.callUpdateWebhookAndCallbackApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.apiKey.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
                StoreUtils.commit(StoreUtils.mutations.apiKey.updateUpdateWebhookAndCallback, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.apiKey.updateLoading, false)
        })
    },


};