class FundTransferResponse {
    bankList = {
    data: [],
    responseCode: null,
    responseMessage: null
    };

    nameEnquiry = {
        accountName: null,
        accountNumber: null,
        bvn: null,
        kyc: null,
        responseCode: null,
        responseDatetime: null,
        responseMessage: null,
        sessionId: null
    };

    postSingleTransaction = {
        sessionId: null,
        contractReference: null,
        reference: null,
        responseCode: null,
        responseMessage: null
    };

    fundTransfer = {
        sessionId: null,
        amount: null,
        contractReference: null,
        creditAccount: null,
        creditAccountName: null,
        customerId: null,
        debitAccountNumber: null,
        narration: null,
        reference: null,
        requestDatetime: null,
        responseDatetime: null,
        responseMessage: null,
        responseCode: null
    };

    read = {
        responseCode: null,
        responseMessage: null,
        dataRead: []
    };

    transactionStatusQuery = {
        trnId: null,
        trnOrganisationId: null,
        trnType: null,
        trnDrCr: null,
        trnAmount: null,
        trnActualAmount: null,
        trnCommission: null,
        trnFee: null,
        trnRate: null,
        trnService: null,
        trnSwitch: null,
        trnDrAccountNumber: null,
        trnDrAccountName: null,
        trnDrInstitutionCode: null,
        trnDrInstitutionName: null,
        trnCrAccountNumber: null,
        trnCrAccountName: null,
        trnCrInstitutionCode: null,
        trnCrInstitutionName: null,
        trnNarration: null,
        trnCbaReference: null,
        trnReference: null,
        trnServiceReference: null,
        trnStage: null,
        trnFtProductCode: null,
        trnStatus: null,
        trnWaitTime: null,
        trnAccountExpiry: null,
        trnRequestTime: null,
        trnResponseTime: null,
        trnCreatedAt: null,
        trnUpdatedAt: null,
        responseCode: null,
        responseMessage: null
    };


    data = {
        code: null,
        name: null
    }

    dataRead = {
        trnId: null,
        trnOrganisationId: null,
        trnType: null,
        trnDrCr: null,
        trnAmount: null,
        trnActualAmount: null,
        trnCommission: null,
        trnFee: null,
        trnRate: null,
        trnService: null,
        trnSwitch: null,
        trnDrAccountNumber: null,
        trnDrAccountName: null,
        trnDrInstitutionCode: null,
        trnDrInstitutionName: null,
        trnCrAccountNumber: null,
        trnCrAccountName: null,
        trnCrInstitutionCode: null,
        trnCrInstitutionName: null,
        trnNarration: null,
        trnReference: null,
        trnServiceReference: null,
        trnStage: null,
        trnFtProductCode: null,
        trnStatus: null,
        trnWaitTime: null,
        trnAccountExpiry: null,
        trnRequestTime: null,
        trnCreatedAt: null,
        trnUpdatedAt: null,
        page: null
    }
}

export default FundTransferResponse;