class VirtualAccountResponse {

    readPartners = {
        responseCode: null,
        responseMessage: null,
        dataPartners: []
    };
    create = {
        responseCode: null,
        responseMessage: null,
        virtualAccountNumber: null,
        virtualAccountBankCode: null,
        virtualAccountBankName: null
    };

    read = {
        responseCode: null,
        responseMessage: null,
        dataRead: []
    };

    readByOrganisationId = {
        responseCode: null,
        responseMessage: null,
        dataReadByOrganisationId: []
    };

    readById = {
        responseCode: null,
        responseMessage: null,
        data: {
            virtualAccountId: null,
            virtualAccountCustomerId: null,
            virtualAccountNumber: null,
            virtualAccountType: null,
            virtualAccountName: null,
            virtualAccountParent: null,
            virtualAccountBankCode: null,
            virtualAccountBankName: null,
            virtualAccountCurrency: null,
            virtualAccountStatus: null,
            virtualAccountAmountControl: null,
            virtualAccountAmount: null,
            virtualAccountOrganisationId: null,
            virtualAccountExtraData: null,
            virtualAccountTotalCredit: null,
            virtualAccountTotalDebit: null,
            virtualAccountValidTime: null,
            virtualAccountCreatedAt: null,
            virtualAccountUpdatedAt: null,
            pageSize: null,
            page: null
        }
    };

    readByAccountNumber = {
        responseCode: null,
        responseMessage: null,
        data: {
            virtualAccountId: null,
            virtualAccountCustomerId: null,
            virtualAccountNumber: null,
            virtualAccountType: null,
            virtualAccountName: null,
            virtualAccountParent: null,
            virtualAccountBankCode: null,
            virtualAccountBankName: null,
            virtualAccountCurrency: null,
            virtualAccountStatus: null,
            virtualAccountAmountControl: null,
            virtualAccountAmount: null,
            virtualAccountOrganisationId: null,
            virtualAccountExtraData: null,
            virtualAccountTotalCredit: null,
            virtualAccountTotalDebit: null,
            virtualAccountValidTime: null,
            virtualAccountCreatedAt: null,
            virtualAccountUpdatedAt: null,
            pageSize: null,
            page: null
        }
    };

    dataPartners = {
        partnerId: null,
        partnerCode: null,
        partnerName: null,
        partnerStatus: null,
        partnerCreatedAt: null,
        partnerUpdatedAt: null
    }

    dataRead = {
        virtualAccountId: null,
        virtualAccountCustomerId: null,
        virtualAccountNumber: null,
        virtualAccountType: null,
        virtualAccountName: null,
        virtualAccountParent: null,
        virtualAccountBankCode: null,
        virtualAccountBankName: null,
        virtualAccountCurrency: null,
        virtualAccountStatus: null,
        virtualAccountAmountControl: null,
        virtualAccountAmount: null,
        virtualAccountOrganisationId: null,
        virtualAccountExtraData: null,
        virtualAccountTotalCredit: null,
        virtualAccountTotalDebit: null,
        virtualAccountValidTime: null,
        virtualAccountCreatedAt: null,
        virtualAccountUpdatedAt: null,
        pageSize: null,
        page: null
    }

    dataReadByOrganisationId = {
        virtualAccountId: null,
        virtualAccountCustomerId: null,
        virtualAccountNumber: null,
        virtualAccountType: null,
        virtualAccountName: null,
        virtualAccountParent: null,
        virtualAccountBankCode: null,
        virtualAccountBankName: null,
        virtualAccountCurrency: null,
        virtualAccountStatus: null,
        virtualAccountAmountControl: null,
        virtualAccountAmount: null,
        virtualAccountOrganisationId: null,
        virtualAccountExtraData: null,
        virtualAccountTotalCredit: null,
        virtualAccountTotalDebit: null,
        virtualAccountValidTime: null,
        virtualAccountCreatedAt: null,
        virtualAccountUpdatedAt: null,
        pageSize: null,
        page: null
    }

}

export default VirtualAccountResponse;