<template>
  <div>
    <dashboard-layout>
      <DashBoardWalletActivitiesView/>
    </dashboard-layout>
  </div>
</template>

<script>
import DashBoardWalletActivitiesView from '../../components/dashboard/DashBoardWalletActivitiesView.vue';
import DashboardLayout from "@/layout/DashboardLayout.vue";

export default {
    name: "WalletActivities",
    components: {DashboardLayout, DashBoardWalletActivitiesView }
}
</script>
<style scoped>
    
</style>