class CustomerRequest {
    updateCustomer = {
        customerAddress: null,
        customerState: null,
        customerLga: null,
        customerCountry: null,
        customerDob: null,
        customerId: null,
    };

    readCustomerById = {
        customerId: null,
    };

    readByEmail = {
        customerEmail: null,
    };

    readByPhone = {
        customerPhone: null,
    };

    addCustomer = {
        customerPhone: null,
        customerFirstName: null,
        customerLastName: null,
        customerCountryCode: null,
        customerCountry: null,
        customerPassword: null,
        customerConfirmPassword: null,
        customerLink: null
    };

    updateStatus = {
        customerOrganisationId: null,
        customerId: null,
        customerRole: null,
        customerStatus: null,
    };

    validateInvite = {
        customerLink: null,
    };

    inviteCustomer = {
        customerOrganisationId: null,
        customerEmail: null,
        customerRole: null,
    };

}

export default CustomerRequest;