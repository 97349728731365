class OrganisationResponse {

    switchStage = {
        responseCode: null,
        responseMessage: null,
        dataSwitchStage: []
    };

    readBalances = {
        walletBalance: {
            accountAllowNegativeBalance: null,
            accountAmountToSave: null,
            accountAutoSave: null,
            accountBalance: null,
            accountBankCode: null,
            accountBranchCode: null,
            accountChecker: null,
            accountClass: null,
            accountComputedBalance: null,
            accountCreatedDate: null,
            accountCurrency: null,
            accountCustomerId: null,
            accountDateOpened: null,
            accountDebitStatus: null,
            accountEndDate: null,
            accountGlCategory: null,
            accountGlLevel1Code: null,
            accountGlLevel2Code: null,
            accountId: null,
            accountInterest: null,
            accountLastAccessTimestamp: null,
            accountLedgerBalance: null,
            accountMaturedAmount: null,
            accountMaturedInterest: null,
            accountMaximumBalance: null,
            accountMinimumBalance: null,
            accountName: null,
            accountNumber: null,
            accountOverdraft: null,
            accountOverdraftRate: null,
            accountOverdraftStatus: null,
            accountPenalty: null,
            accountStartDate: null,
            accountStatus: null,
            accountTenure: null,
            accountType: null,
            accountUpdatedDate: null,
            customerEmail: null,
            customerPhoneNumber: null,
            responseCode: null,
            responseMessage: null,
            dataVirtualAccounts: []
        },
        referralBalance: {
            accountBalance: null,
            accountTotalCredit: null,
            accountTotalDebit: null
        },
        responseCode: null,
        responseMessage: null
    };

    readOrganisationsByCustomerId = {
    responseCode: null,
    responseMessage: null,
    data: []
    };

    readOrganisationTransactions = {
        responseCode: null,
        responseMessage: null,
        dataReadOrganisationTransactions: []
    };

    referralStats = {
        responseCode: null,
        responseMessage: null,
        totalReferrals: null,
        totalReferralAmount: null,
        dataReferral: []
    };

    readOrganisationById = {
        organisationId: null,
        organisationCustomerId: null,
        organisationAccountNumber: null,
        organisationName: null,
        organisationPhone: null,
        organisationEmail: null,
        organisationStatus: null,
        organisationCreatedAt: null,
        organisationUpdatedAt: null,
        organisationStage: null,
        organisationFtOutwardPrd: null,
        organisationFtInternalPrd: null,
        organisationFtInwardPrd: null,
        organisationReferralCode: null,
        organisationInviteCode: null,
        organisationNumberOfWallet: null,
        organisationNumberOfVirtualAccount: null,
        organisationNumberOfWalletDev: null,
        organisationNumberOfVirtualAccountDev: null,
        organisationTotalCommission: null,
        organisationReferralBonus: null,
        organisationNumberOfTemporaryVirtualAccount: null,
        organisationNumberOfTemporaryVirtualAccountDev: null,
        organisationTotalKyc: null,
        responseCode: null,
        responseMessage: null
    };

    data = {
        organisationId: null,
        organisationCustomerId: null,
        organisationAccountNumber: null,
        organisationName: null,
        organisationPhone: null,
        organisationEmail: null,
        organisationStatus: null,
        organisationCreatedAt: null,
        organisationUpdatedAt: null,
        organisationStage: null,
        organisationFtOutwardPrd: null,
        organisationFtInternalPrd: null,
        organisationFtInwardPrd: null,
        organisationReferralCode: null,
        organisationInviteCode: null,
        organisationTotalCommission: null,
        organisationReferralBonus: null,
        organisationNumberOfVirtualAccount: null,
        organisationNumberOfWallet: null,
        organisationNumberOfWalletDev: null,
        organisationNumberOfVirtualAccountDev: null,
        organisationNumberOfTemporaryVirtualAccount: null,
        organisationNumberOfTemporaryVirtualAccountDev: null,
        organisationTotalKyc: null,
        customerOrganisationId: null,
        customerOrganisationOrganisationId: null,
        customerOrganisationCustomerId: null,
        customerOrganisationRole: null,
        customerOrganisationStatus: null,
        customerOrganisationCreatedAt: null,
        customerOrganisationUpdatedAt: null
    };

    dataSwitchStage = {
        organisationId: null,
        organisationCustomerId: null,
        organisationAccountNumber: null,
        organisationName: null,
        organisationLogo: null,
        organisationAddress: null,
        organisationCity: null,
        organisationState: null,
        organisationPhone: null,
        organisationEmail: null,
        organisationType: null,
        organisationStatus: null,
        organisationCreatedAt: null,
        organisationUpdatedAt: null,
        organisationStage: null,
        organisationFtOutwardPrd: null,
        organisationFtInternalPrd: null,
        organisationFtInwardPrd: null,
        organisationReferralCode: null,
        customerOrganisationId: null,
        customerOrganisationOrganisationId: null,
        customerOrganisationCustomerId: null,
        customerOrganisationRole: null,
        customerOrganisationStatus: null,
        customerOrganisationCreatedAt: null,
        customerOrganisationUpdatedAt: null
    }

    dataVirtualAccounts = {
        accountBankCode: null,
        accountBranchCode: null,
        accountBvn: null,
        accountChecker: null,
        accountCreatedDate: null,
        accountCurrency: null,
        accountCustomerId: null,
        accountDateOpened: null,
        accountDob: null,
        accountExtraData: null,
        accountGender: null,
        accountId: null,
        accountLastAccessTimestamp: null,
        accountMaker: null,
        accountName: null,
        accountNumber: null,
        accountOtherBankCode: null,
        accountOtherBankName: null,
        accountParent: null,
        accountStatus: null,
        accountUpdatedDate: null,
        page: null
    }

    dataReferral = {
        organisationId: null,
        organisationCustomerId: null,
        organisationAccountNumber: null,
        organisationName: null,
        organisationLogo: null,
        organisationAddress: null,
        organisationCity: null,
        organisationState: null,
        organisationPhone: null,
        organisationEmail: null,
        organisationType: null,
        organisationStatus: null,
        organisationCreatedAt: null,
        organisationUpdatedAt: null,
        organisationStage: null,
        organisationFtOutwardPrd: null,
        organisationFtInternalPrd: null,
        organisationFtInwardPrd: null,
        organisationReferralCode: null,
        organisationInviteCode: null,
        organisationTotalCommission: null,
        organisationNumberOfVirtualAccount: null,
        organisationNumberOfWallet: null
    }

}

export default OrganisationResponse