class VirtualAccountRequest {
    create = {
        virtualAccountName: null,
        virtualAccountParent: null,
        virtualAccountBankCode: null,
        virtualAccountAmountControl: null,
        virtualAccountType: null,
        virtualAccountExtraData: null,
        virtualAccountValidTime: null
    };

    update = {
        virtualAccountName: null,
        virtualAccountNumber: null
    };

    readById = {
        virtualAccountId: null
    };

    readByAccountNumber = {
        virtualAccountNumber: null
    };

}

export default VirtualAccountRequest;