import client  from "./BaseService";

export default {
    callReadOrganisationsByCustomerIdApi(payload) {
        return client.apiClient.post("organisation/read-organisations-by-user-id", payload);
    },
    callUpdateOrganisationApi(payload) {
        return client.apiClient.post("organisation/update", payload);
    },
    callTransactionEnquiryApi(payload) {
        return client.apiClient.post("organisation/transaction-enquiry", payload);
    },
    callBalanceEnquiryOrganisationApi(payload) {
        return client.apiClient.post("organisation/balance-enquiry", payload);
    },
    callReferralStatsApi() {
        return client.apiClient.post("organisation/referral-stats", {});
    },
    callReadOrganisationByIdApi(payload) {
        return client.apiClient.post("organisation/read-by-id", payload);
    },
    callSwitchStageApi(payload) {
        return client.apiClient.post("organisation/update/stage", payload);
    },
    callReadBalancesApi(payload) {
        return client.apiClient.post("organisation/read-balances", payload);
    },
    callAddOrganisationApi(payload) {
        return client.apiClient.post("organisation/add", payload);
    },
    callReadOrganisationTransactionsApi(payload) {
        return client.apiClient.post("transaction/read-by-organisation-id", payload);
    },
};