<template>
  <div class="alpha">

    <div class="image">
      <img src="@/assets/afropaylogo.svg" alt="inter-switch-logo" >
    </div>

    <div class="text-area">
      <h3>Set your password</h3>
    </div>

    <div class="form-alpha">
      <form @submit.prevent="completeEnrollment">

        <div class="has-addons">
          <input v-if="showPassword" v-model="model.customerPassword"  type="text" class="input-form-1 password"   placeholder="Create Passcode"   />
          <input v-else type="password" class="input-form-1  password" v-model="model.customerPassword"   placeholder="Create Passcode"   >
          <div class="space">
            <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }" @click="toggleShow"></i>
          </div>
        </div>

        <div class="has-addons">
          <input v-if="showPassword2" v-model="model.customerConfirmPassword"   type="text" class="input-form-1 password"   placeholder="Confirm password"   />
          <input v-else type="password" v-model="model.customerConfirmPassword"  class="input-form-1  password"   placeholder="Confirm password"   >
          <div class="space">
            <i class="fas" :class="{ 'fa-eye-slash': showPassword2, 'fa-eye': !showPassword2 }" @click="toggleShow2"></i>
          </div>
        </div>

        <div class="submit">
          <base-button :loading="loading">Proceed</base-button>
        </div>

      </form>
    </div>


  </div>
</template>

<script>
import AuthenticationRequest from "../../../model/request/AuthenticationRequest";
import StoreUtils from "../../../util/baseUtils/StoreUtils";
import {mapState} from "vuex";
import BaseButton from "@/components/baseComponents/buttons/BaseButton.vue";

export default {
  name: "SignUpSetPasscode",
  components: {BaseButton},
  data(){
    return {
      showPassword: false,
      showPassword2: false,
      model: new AuthenticationRequest().completeEnrollment
    }
  },
  methods:{
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShow2() {
      this.showPassword2 = !this.showPassword2;
    },

    completeEnrollment(){
      StoreUtils.dispatch(StoreUtils.actions.auth.completeEnrollment, {
        customerOtp: this.signUpFormData.customerOtp,
        customerPassword: this.model.customerPassword,
        customerConfirmPassword: this.model.customerConfirmPassword,
        customerEmail: this.signUpFormData.customerEmail,
        customerBusinessName: this.signUpFormData.customerBusinessName,
        customerReferralCode: ""
      })
    },
  },
  computed:{
    ...mapState({
      loading: state => state.auth.loading,
      auth: state => state.auth
    }),
    signUpFormData() {
      return StoreUtils.rootGetters(StoreUtils.getters.auth.getSignUpFormData)
    },
    buttonLabel() {
      return (this.showPassword) ? "Hide" : "Show";
    },
    buttonLabel2() {
      return (this.showPassword2) ? "Hide" : "Show";
    }
  }
}
</script>

<style scoped>
.alpha{
  margin-top: 12%;
}

.text-area h3{
  padding-bottom: 0;
  margin-bottom: 0;
  text-align: center;
  letter-spacing: 0.5px;
  color: #101828;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.text-area p{
  padding-top: 2px;
  margin-top: 2px;
  text-align: center;
  letter-spacing: 0.5px;
}

.text-area{
  margin-bottom: 1%;
}

.form-alpha{
  margin-left: 38%;
  margin-right: 38%;
}

.image{
  display: flex;
  justify-content: center;
  margin-bottom: 0.8%;
}


.input-form-1{
  order: 1;
  width: 100%;
  padding: 13px 20px;
  margin: 15px 0;
}


input {
  box-sizing: border-box;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  outline: none;
  color: #667085;
  letter-spacing: 0.5px;
}

input:focus {
  border: 1px solid #24405A;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.has-addons{
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  align-content: center;
}

button{
  background-color: transparent;
}

.fas{
  font-size: 13px;
  margin-top: 10%;
}

.space{
  padding-top: 10.4px;
  padding-bottom: 11px;
  padding-right: 10px;
  border: 1px solid #d0d5dd;
  border-left-style: none;
  border-radius: 0 8px 8px 0;
  font-size: 1rem;
}

.input-form-1.password {
  border-right-style: none;
  border-radius: 8px 0 0 8px;
}


.submit{
  margin-top: 3%;
}



</style>