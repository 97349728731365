<template>
  <div>
    <sign-up-set-passcode/>
  </div>
</template>

<script>
import SignUpSetPasscode from "../../components/auth/signUp/SignUpSetPasscode";
export default {
  name: "SignUpSetPassword",
  components: {SignUpSetPasscode}
}
</script>

<style scoped>

</style>