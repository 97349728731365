<template>
  <div class="alpha">
    <dash-board-side-menu/>
    <dash-content>

      <div class="section-4">
        <p class="section-4-header">Settings</p>
      </div>

      <div class="section-2">
        <p class="active" >Profile</p>
        <p @click="webHooks">API Keys And Webhooks</p>
        <p @click="user">User</p>
      </div>

      <div class="dropdown">
        <dropdown-menu :message="parentMessage">
          <div class="second-part">
            <p class="info-text">You can update your personal info here.</p>
            <br/>
            <form @submit.prevent="">

              <div class="image-part">
                <img src="@/assets/Avatar.svg" alt="personal-image" />
                <i class='bx bx-pencil'></i>
              </div>


              <div class="input-form-2">
                <input type="text" v-model="customerFirstName" placeholder="First name" class="input-form-1" required="required" />
                <input type="text" v-model="customerLastName" placeholder="Last name" class="input-form-1" required="required" />
              </div>

              <div class="input-form-2">
                <input type="text" v-model="customerEmail"  placeholder="Email address" class="input-form-1" required="required" />
                <input type="number" v-model="customerPhone" placeholder="Phone Number" class="input-form-1" required="required" />
              </div>


              <br/>
              <div class="separate">
                <base-button>Update</base-button>
              </div>

            </form>
          </div>
        </dropdown-menu>
        <dropdown-menu :message="parentMessage2">
          <div class="second-part-2">
            <p class="info-text">Update your business information here.</p>
            <br/>
            <form @submit.prevent="update">
              <div class="input-form-2">
                <div class="input-form-3">
                  <label>Business Name</label>
                  <input type="text" v-model="organisationName"  placeholder="Enter Business Name" class="input-form-1" required="required" />
                </div>
                <div class="input-form-3">
                  <label>Company Email</label>
                  <input type="text" v-model="organisationEmail" placeholder="Enter Company Email" class="input-form-1" required="required" />
                </div>
              </div>

              <div class="input-form-2">
                <div class="input-form-3">
                  <label>Company Phone</label>
                  <input type="number" v-model="organisationPhone"  placeholder="Enter Company Phone" class="input-form-1" required="required" />
                </div>
                <div class="input-form-3">
                  <label>City</label>
                  <input type="text" v-model="organisationCity"  placeholder="Enter City" class="input-form-1" required="required" />
                </div>
              </div>

              <div class="input-form-2">
                <div class="input-form-3">
                  <label>Address</label>
                  <input type="text" v-model="organisationAddress" placeholder="Enter Address" class="input-form-1" required="required" />
                </div>

                <div class="input-form-3">
                  <label>State</label>
                  <input type="text" v-model="organisationState"  placeholder="Enter State" class="input-form-1" required="required" />
                </div>
              </div>

              <div class="input-form-2">
                <div class="input-form-3">
                  <label>Organisation Website</label>
                  <input type="text" v-model="organisationWebsite"  placeholder="Enter Organisation Website" class="input-form-1" required="required" />
                </div>

                <div class="input-form-3">
                  <label>RC / BN Number</label>
                  <input type="text" v-model="organisationRegistrationNo"  placeholder="Enter RC / BN Number" class="input-form-1" required="required" />
                </div>
              </div>

              <div class="input-form-2">
                <div class="input-form-3">
                  <label>Organization Type</label>
                  <input type="text" v-model="organisationType" placeholder="Enter Organization Type" class="input-form-1" required="required" />
                </div>
              </div>

              <p class="inner-header">Director 1</p>
              <br/>

              <div class="input-form-2">
                <div class="input-form-3">
                  <label>Full Name</label>
                  <input type="text" v-model="organisationDirectorName" placeholder="Enter Full Name" class="input-form-1" required="required" />
                </div>
              </div>

              <div class="input-form-2">
                <div class="input-form-3">
                  <label>Dob</label>
                  <input type="date" v-model="organisationDirectorDob"  placeholder="Enter Company Phone" class="input-form-1" required="required" />
                </div>
                <div class="input-form-3">
                  <label>BVN</label>
                  <input type="number" v-model="organisationDirectorBvn"  placeholder="Enter BVN" class="input-form-1" required="required" />
                </div>
              </div>

              <div class="input-form-2">

                <div class="input-form-3">
                  <label>ID Card Type</label>
                  <input type="number" v-model="organisationDirectorIdCardType"  placeholder="Enter ID Card Type" class="input-form-1" required="required" />
                </div>

                <div class="input-form-3">
                  <br/>
                  <input type="file"  placeholder="Enter State" class="input-form-1" required="required" />
                </div>


              </div>


              <p class="inner-header">Director 2</p>
              <br/>

              <div class="input-form-2">
                <div class="input-form-3">
                  <label>Full Name</label>
                  <input type="text" v-model="organisationDirectorName2"  placeholder="Enter Full Name" class="input-form-1" required="required" />
                </div>
              </div>

              <div class="input-form-2">
                <div class="input-form-3">
                  <label>Dob</label>
                  <input type="date" v-model="organisationDirectorDob2" placeholder="Enter Company Phone" class="input-form-1" required="required" />
                </div>
                <div class="input-form-3">
                  <label>BVN</label>
                  <input type="number"  v-model="organisationDirectorBvn2"  placeholder="Enter BVN" class="input-form-1" required="required" />
                </div>
              </div>

              <div class="input-form-2">

                <div class="input-form-3">
                  <label>ID Card Type</label>
                  <input type="number" v-model="organisationDirectorIdCardType2" placeholder="Enter ID Card Type" class="input-form-1" required="required" />
                </div>

                <div class="input-form-3">
                  <br/>
                  <input type="file"  placeholder="Enter State" class="input-form-1" required="required" />
                </div>


              </div>

              <br/>
              <div class="separate">
                <base-button>Update</base-button>
              </div>

            </form>

          </div>
        </dropdown-menu>
        <dropdown-menu :message="parentMessage3">
          <div class="second-part-3">
            <form @submit.prevent="showSuccessMessage" id="myForm">
              <p class="info-text">Submit important documents about your business. This will help us verify your business and have your account upgraded.</p>
              <br/>
              <div class="input-form-2">
                <div class="input-form-3">
                  <label>CAC Certificate of Incorporation/Registration</label>
                  <input type="file"  placeholder="Live Secret Key" class="input-form-1" required="required" />
                </div>
              </div>

              <div class="input-form-2">
                <div class="input-form-3">
                  <label>Memorandum and Article of Association</label>
                  <input type="file"  placeholder="Live Secret Key" class="input-form-1" required="required" />
                </div>
              </div>

              <div class="input-form-2">
                <div class="input-form-3">
                  <label>Other relevant document</label>
                  <input type="file"  placeholder="Live Secret Key" class="input-form-1" required="required" />
                </div>
              </div>
              <br/>
              <div class="separate">
                <base-button>Submit</base-button>
              </div>

            </form>
          </div>
        </dropdown-menu>
        <dropdown-menu :message="parentMessage5">
          <div class="second-part">
            <form @submit.prevent="changePassword">

              <div class="input-form-2">
                <input type="password" v-model="model2.customerOldPassword" placeholder="Enter current password" class="input-form-1" required="required" />
              </div>

              <div class="input-form-2">
                <input type="password" v-model="model2.customerPassword"  placeholder="Enter new password" class="input-form-1" required="required" />
                <input type="password" v-model="model2.customerPasswordConfirmation"  placeholder="Confirm new Password" class="input-form-1" required="required" />
              </div>



              <br/>
              <div class="separate">
                <base-button :loading="loading2">Change Password</base-button>
              </div>

            </form>
          </div>
        </dropdown-menu>
      </div>


    </dash-content>
  </div>
</template>

<script>
import DashBoardSideMenu from "@/components/dashboard/DashBoardSideMenu.vue";
import DashContent from "@/components/baseComponents/dash/DashContent.vue";
import RouterUtils from "@/util/baseUtils/RouterUtils";
import DropdownMenu from "@/components/baseComponents/dropdown/DropdownMenu.vue";
import BaseButton from "@/components/baseComponents/buttons/BaseButton.vue";
import {mapState} from "vuex";
import StoreUtils from "@/util/baseUtils/StoreUtils";
import OrganisationRequest from "@/model/request/OrganisationRequest";
import AuthenticationRequest from "@/model/request/AuthenticationRequest";
import swal from 'sweetalert';

export default {
  name: "DashBoardSettingsView",
  components: {BaseButton, DropdownMenu, DashContent, DashBoardSideMenu},
  computed:{
    ...mapState({
      loading: state => state.organisation.loading,
      loading2: state => state.auth.loading,
      auth: state => state.auth,
      userInfo: state => state.auth.userInfo,
      readOrganisationById: state => state.organisation.readOrganisationById
    }),
  },
  methods:{
    user(){
      RouterUtils.navigateTo(RouterUtils.routes.dashboard.settingsUser.name)
    },
    webHooks(){
      RouterUtils.navigateTo(RouterUtils.routes.dashboard.aPIKeysAndWebhooks.name)
    },
    assign(){
      this.customerFirstName = this.userInfo.customerFirstName
      this.customerLastName = this.userInfo.customerLastName
      this.customerEmail = this.userInfo.customerEmail
      this.customerPhone = this.userInfo.customerPhone
      this.organisationName = this.readOrganisationById.organisationName
      this.organisationEmail = this.readOrganisationById.organisationEmail
      this.organisationPhone = this.readOrganisationById.organisationPhone
      this.organisationCity = this.readOrganisationById.organisationCity
      this.organisationAddress = this.readOrganisationById.organisationAddress
      this.organisationState = this.readOrganisationById.organisationState
      this.organisationWebsite = this.readOrganisationById.organisationWebsite
      this.organisationRegistrationNo = this.readOrganisationById.organisationRegistrationNo
      this.organisationType = this.readOrganisationById.organisationType
      this.organisationDirectorName = this.readOrganisationById.organisationDirectorName
      this.organisationDirectorDob = this.readOrganisationById.organisationDirectorDob
      this.organisationDirectorBvn = this.readOrganisationById.organisationDirectorBvn
      this.organisationDirectorIdCardType = this.readOrganisationById.organisationDirectorIdCardType
      this.organisationDirectorName2 = this.readOrganisationById.organisationDirectorName2
      this.organisationDirectorDob2 = this.readOrganisationById.organisationDirectorDob2
      this.organisationDirectorBvn2 = this.readOrganisationById.organisationDirectorBvn2
      this.organisationDirectorIdCardType2 = this.readOrganisationById.organisationDirectorIdCardType2
    },
    update(){
      StoreUtils.dispatch(StoreUtils.actions.organisation.organisationUpdateOrganisation, {
        organisationId: this.userInfo.currentOrganisation.organisationId,
        organisationAddress: this.organisationAddress,
        organisationName: this.organisationName,
        organisationLogo: "Company Logo",
        organisationRegistrationNo: this.organisationRegistrationNo,
        organisationRegistrationDate: "1/12/2032",
        organisationCity: this.organisationCity,
        organisationState: this.organisationState,
        organisationPhone: this.organisationPhone,
        organisationMemorandumAndArticleOfAssociation: "0893738937",
        organisationEmail: this.organisationEmail,
        organisationType: this.organisationType,
        organisationCustomerId: this.userInfo.currentOrganisation.organisationCustomerId,
        organisationAccountNumber: this.userInfo.currentOrganisation.organisationAccountNumber,
        organisationStatus: this.userInfo.currentOrganisation.organisationStatus,
        organisationWebsite: this.organisationWebsite,
        organisationCountry: "Nigeria",
        organisationIndustryType: this.organisationType,
        organisationFormCac: "Telecomunication",
        organisationCertificateIncorpation: "Cac Doucment",
        organisationScumlCertificate: "Issued by Efcc",
        organisationTin: "Tax Income",
        organisationDirectorName:this.organisationDirectorName,
        organisationDirectorDob: this.organisationDirectorDob,
        organisationDirectorIdCard: "ffff",
        organisationDirectorIdCardType: this.organisationDirectorIdCardType,
        organisationDirectorName2: this.organisationDirectorName2,
        organisationDirectorBvn2: this.organisationDirectorBvn2,
        organisationDirectorDob2: this.organisationDirectorDob2,
        organisationDirectorIdCard2: "ffff",
        organisationDirectorIdCardType2: this.organisationDirectorIdCardType2,
        organisationDirectorBvn: this.organisationDirectorBvn,
      })
    },
    changePassword(){
      StoreUtils.dispatch(StoreUtils.actions.auth.changePassword, {
        customerOldPassword: this.model2.customerOldPassword,
        customerPassword: this.model2.customerPassword,
        customerPasswordConfirmation: this.model2.customerPasswordConfirmation,
        customerEmail: this.userInfo.customerEmail
      })
    },
    showSuccessMessage() {
      swal('Success!', 'Operation completed successfully.', 'success');
      document.getElementById("myForm").reset();
    },
 },
  created() {
    this.assign()
  },
  async mounted() {
    await StoreUtils.dispatch(StoreUtils.actions.organisation.organisationReadOrganisationById, {
      organisationId: this.userInfo.currentOrganisation.organisationId
    })
  },
  data() {
    return {
      model: new OrganisationRequest().updateOrganisation,
      model2: new AuthenticationRequest().changePassword,
      parentMessage: 'Personal Information',
      parentMessage2: 'Business Information',
      parentMessage3: 'Document upload',
      parentMessage5: 'Change Password',
      customerFirstName: "",
      customerLastName: "",
      customerEmail: "",
      customerPhone: "",
      organisationName: "",
      organisationEmail: "",
      organisationPhone: "",
      organisationCity: "",
      organisationAddress: "",
      organisationState: "",
      organisationWebsite: "",
      organisationRegistrationNo: "",
      organisationType: "",
      organisationDirectorName: "",
      organisationDirectorDob: "",
      organisationDirectorBvn: "",
      organisationDirectorIdCardType: "",
      organisationDirectorName2: "",
      organisationDirectorDob2: "",
      organisationDirectorBvn2: "",
      organisationDirectorIdCardType2: "",
    };
  }
}
</script>

<style scoped>
.alpha{
  display: flex;
}

.section-4-header{
  padding-left: 1.5%;
  margin-top: 1.5%;
  font-weight: 700;
  font-size: 19px;
  line-height: 25px;
  color: #353542;
}

.section-2{
  padding-left: 3%;
  margin-left: 3%;
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 470px;
  height: 46px;
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 8px;
  color: #667085;
}

.section-2 p{
  margin-right: 11%;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.active{
  display: flex;
  align-content: center;
  align-items: center;
  color: #0765FF;
  height: 34px;
  padding-right: 30px;
  padding-left: 30px;
  background: #ECF2FF;
  border-radius: 6px;
}

.second-part{
  padding: 24px;
  padding-top: unset;
  gap: 24px;
  width: 675px;
  height: 370px;
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  border-top: none;
  border-radius: 0 0 5px 5px ;
}


.second-part-2{
  padding: 24px;
  padding-top: unset;
  gap: 24px;
  width: 675px;
  height: 100%;
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  border-top: none;
  border-radius: 0 0 5px 5px ;
}

.second-part-3{
  padding: 24px;
  padding-top: unset;
  gap: 24px;
  width: 675px;
  height: 100%;
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  border-top: none;
  border-radius: 0 0 5px 5px ;
}


.second-text-1{
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
  margin-top: 2%;
  margin-bottom: 4%;
}

.input-form-2{
  display: flex;
  justify-content: center;
  /*margin-top: 2%;*/
  margin-bottom: 2%;
  margin-right: 1%;
}

.input-form-1{
  order: 1;
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  box-sizing: border-box;
  margin-bottom: 2%;
  margin-right: 2%;
  margin-left: 2%;
}

input {
  box-sizing: border-box;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  -webkit-transition: 0.3s;
  padding-top: 12px;
  padding-bottom: 12px;
  transition: 0.3s;
  outline: none;
  color: #667085;
  letter-spacing: 0.5px;
  font-size: 12px;
}


input:focus {
  border: 1px solid #24405A;
}


select {
  box-sizing: border-box;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  -webkit-transition: 0.3s;
  padding-top: 12px;
  padding-bottom: 12px;
  transition: 0.3s;
  outline: none;
  color: #667085;
  letter-spacing: 0.5px;
}

select:focus {
  border: 1px solid #24405A;
}

.dropdown{
  margin-left: 3.5%;
}

.bx-pencil{
  padding: 6px;
  gap: 6px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 5px;
}

.image-part{
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 4%;
}
.separate{
  margin-left: 2%;
  margin-right: 2%;
}
.info-text{
  color: #667085;
  font-size: 15px;
}
.input-form-3{
  width: 100%;
  margin-right: 1.5%;
  margin-left: 1.5%;
}

label{
  color: #667085;
  font-size: 15px;
}

</style>