<template>
  <div class="alpha">
    <dash-board-side-menu/>

    <dash-content>
      <fund-wallet-modal @close="hideDialog" v-if="dialogIsVisible" />
      <withdraw-modal @close="hideDialog2" @open="showDialog3" v-if="dialogIsVisible2"/>

      <withdraw-modal2 @close="hideDialog3" @open="showDialog2" v-if="dialogIsVisible3"/>
<!-- 
      <div class="section-1">
        <img src="@/assets/Notification.svg" alt="Notification">
        <div class="vl"></div>
        <img src="@/assets/avatar-dash.svg" alt="avatar-dash">
        <p class="profile-name">Blessing Adegboye</p>
      </div> -->

      <div class="section-2">
        <div class="section-2-part-1">
          <p>Dashboard</p>
        </div>

        <div class="section-2-part-2">
          <p class="fund-wallet" @click="showDialog">
            <i class='bx bx-plus' ></i>
            Fund Wallet
          </p>
          <p class="withdraw" @click="showDialog2">
            <i class='bx bx-up-arrow-circle'></i>
            Withdraw
          </p>
        </div>
      </div>

      <div class="section-3">

        <div class="section-3-content">
          <div class="content-1">
            <p class="content-1-text-1">Wallet Balance</p>
            <p class="content-1-text-2">{{this.wallets.data[0].accountBalance}}</p>


          </div>
          <div class="content-2">
            <img src="@/assets/wallet-balance-icon.svg" alt="wallet-balance-icon"/>
          </div>
        </div>


        <div class="vl-2"></div>

        <div class="section-3-content">
          <div class="content-1">
            <p class="content-1-text-1">Number Of Wallet</p>
            <p class="content-1-text-2">{{this.wallets.data.length}}</p>
          </div>
          <div class="content-2">
            <img src="@/assets/wallet-number-icon.svg" alt="wallet-balance-icon"/>
          </div>
        </div>

        <div class="vl-2"></div>

        <div class="section-3-content">
          <div class="content-1">
            <p class="content-1-text-1">Number Of Virtual Accounts</p>
            <p class="content-1-text-2">{{this.readByOrganisationId.data.length}}</p>
          </div>
          <div class="content-2">
            <img src="@/assets/wallet-number-icon.svg" alt="wallet-balance-icon"/>
          </div>
        </div>

      </div>

      <div class="section-4">
        <p class="section-4-header">Transactions</p>

        <div class="forth-section">

          <div class="form-group fg--search">
            <button type="submit"><i class="fa fa-search"></i></button>
            <input type="text" class="input" placeholder="Search transaction..."/>
          </div>

          <div class="action">

            <div class="action-content">
              <img src="@/assets/Filterslines.svg"  alt="Export"/>
              <p>Filter</p>
            </div>

          </div>

        </div>
      </div>

      <div class="section-5">


        <div class="empty-state" v-if="this.readWalletActivity.data.length === 0">
          <img src="@/assets/empty.svg" alt="empty">
          <p class="empty-state-text-1">You have nothing to see</p>
          <p class="empty-state-text-2">This is where your Transactions will appear</p>
          <p class="empty-state-text-3">
            <i class='bx bx-plus' ></i>
            Transaction
          </p>
        </div>


       <div class="table" v-if="this.readWalletActivity.data.length >0">
         <table>
           <tr>
             <th>Sender</th>
             <th>Receiver</th>
             <th>Amount</th>
             <th>Transaction Reference</th>
             <th>Payment Reference</th>
             <th>Date</th>
             <th></th>
           </tr>

           <tbody v-for="child in paginatedItems" :key="child.key">
           <tr>
             <td>{{child.accountName}}</td>
             <td>{{child.counterPartyAccountName}}</td>
             <td>{{child.amount}}</td>
             <td>{{child.paymentReference | formatTextWithEllipsis}}</td>
             <td>{{child.contractReference | formatTextWithEllipsis}}</td>
             <td>{{child.transactionDate | formatTextWithEllipsis}}</td>
             <td>
               <div class="separate-1">
                 <p>
                   <i class='bx bx-log-in-circle' v-if="child.drCr === 'CR'"></i>
                   <i class='bx bx-log-out-circle' v-if="child.drCr === 'DR'" ></i>
                 </p>
               </div>
             </td>
           </tr>
           </tbody>

         </table>
         <div class="pagination">
           <button @click="previousPage" :disabled="currentPage === 1" class="previous">Previous</button>
           <div class="page-indicator">
             Page {{ currentPage }} of {{ totalPages }}
           </div>
           <button @click="nextPage" :disabled="currentPage === totalPages" class="previous">Next</button>
         </div>
       </div>


      </div>
    </dash-content>
  </div>
</template>

<script>
import DashBoardSideMenu from "@/components/dashboard/DashBoardSideMenu";
import DashContent from "@/components/baseComponents/dash/DashContent";
import FundWalletModal from "@/components/baseComponents/modals/FundWalletModal.vue";
import WithdrawModal from "@/components/baseComponents/modals/WithdrawModal.vue";
import {mapState} from "vuex";
import StoreUtils from "@/util/baseUtils/StoreUtils";
import WithdrawModal2 from "@/components/baseComponents/modals/WithdrawModal2.vue";
export default {
  name: "DashBoardOverView",
  components: {WithdrawModal2, WithdrawModal, FundWalletModal, DashContent, DashBoardSideMenu,},
  data() {
    return {
      dialogIsVisible: false,
      dialogIsVisible2: false,
      dialogIsVisible3: false,
      currentPage: 1,
      itemsPerPage: 7,
    };
  },
  computed:{
    ...mapState({
      loading: state => state.fundTransfer.loading,
      auth: state => state.auth,
      userInfo: state => state.auth.userInfo,
      readWalletActivity: state => state.wallet.readWalletActivity,
      wallets: state => state.wallet.wallets,
      readByOrganisationId: state => state.virtualAccount.readByOrganisationId,
    }),
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.readWalletActivity.data.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.readWalletActivity.data.length / this.itemsPerPage);
    },
    totalSum: function() {
      return this.wallets.data.reduce((acc, obj) => acc + obj.accountBalance, 0);
    }
  },
  methods: {
    hideDialog() {
      this.dialogIsVisible = false;
    },
    showDialog() {
      this.dialogIsVisible = true;
    },
    hideDialog2() {
      this.dialogIsVisible2 = false;
    },
    showDialog2() {
      this.dialogIsVisible2 = true;
    },
    hideDialog3() {
      this.dialogIsVisible3 = false;
    },
    showDialog3() {
      this.dialogIsVisible3 = true;
    },

    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },

    goToPage(pageNumber) {
      if (pageNumber > 0 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
      }
    },

  },
  async mounted() {
    await StoreUtils.dispatch(StoreUtils.actions.wallet.walletReadWalletActivity,)
    await StoreUtils.dispatch(StoreUtils.actions.virtualAccount.virtualAccountReadByOrganisationId,)
    await StoreUtils.dispatch(StoreUtils.actions.wallet.walletRead)
  },
}
</script>

<style scoped>
.alpha{
  display: flex;
}
/* .section-1{
  display: flex;
  justify-content: right;
  align-content: center;
  align-items: center;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  padding-right: 3%;
  background-color: #FFFFFF;
}
.vl{
  border-left: 1.5px solid #E7E8F0;
  height: 35px;
  margin-left: 1%;
  margin-right: 1%;
} */
/* .profile-name{
  padding-left: 1%;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #58596C;
} */

.section-2{
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding-right: 3%;
  padding-left: 1.5%;
  margin-top: 1.5%;
}
.section-2-part-1 p{
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #101828;
}

.fund-wallet{
  display: flex;
  align-content: center;
  align-items: center;
  padding: 8px 14px;
  gap: 8px;
  font-size: 12px;
  width: 125px;
  height: 33px;
  background: #1570EF;
  color: #ffffff;
  border: 1px solid #1570EF;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  margin-right: 3%;
}

.fund-wallet:hover{
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.withdraw{
  display: flex;
  align-content: center;
  align-items: center;
  padding: 8px 14px;
  gap: 8px;
  font-size: 12px;
  width: 110px;
  height: 33px;
  background: #FFFFFF;
  color: #344054;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
}

.withdraw:hover{
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.section-2-part-2{
  display: flex;
  align-content: center;
  align-items: center;
}

.section-2-part-1 p{
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #101828;
}

.section-3{
  display: flex;
  align-content: center;
  align-items: center;
  padding: 15px 25px;
  gap: 24px;
  width: 1150px;
  height: 120px;
  background: #FFFFFF;
  border: 1px solid #F2F4F7;
  border-radius: 8px;
  margin-left: 2%;
  margin-top: 1.5%;
}

.section-3-content{
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  width: 33%;
}

.vl-2{
  border-left: 1.5px solid #E7E8F0;
  height: 50px;
  margin-left: 1%;
  margin-right: 1%;
}

.content-1-text-1{
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #667085;
}

.content-1-text-2{
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #1570EF;
}

.section-4-header{
  padding-left: 1.5%;
  margin-top: 1.5%;
  font-weight: 700;
  font-size: 19px;
  line-height: 25px;
  color: #353542;
}

.fg--search {
  background: white;
  position: relative;
  width: 400px;
  margin-left: 1%;
}
.fg--search input {
  width: 100%;
  padding: 10px;
  padding-left: 15px;
  display: block;
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  font-family: 'Outfit', sans-serif;
}

.fg--search button {
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  margin-top: 5px;
}

.fa-search{
  color: #667085;
  margin-right: 10px;
}

.forth-section{
  display: flex;
  justify-content: space-between;
  margin-left: 1%;
  margin-right: 2%;
  margin-top: 15px;
}

.action{
  display: flex;
  align-items: center;
  align-content: center;
}

.action-content{
  display: flex;
  align-items: center;
  align-content: center;
  padding: 8px 14px;
  gap: 8px;
  width: 88px;
  height: 36px;
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
  margin-right: 13px;
}

.action-content:hover{
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.action-content p {
  color: #667085;
  font-size: 13px;
}



table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 2%;
}
.table{
  margin-left: 2%;
  margin-right: 3%;
}

tr{
  border: 1px solid #E3EBF6;
}

th {

  background-color: #F9FBFD;
  padding: 10px;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 14px;
  color: #667085;
  text-align: center;
}

td {
  /*border: 1px solid #E3EBF6;*/
  text-align: center;
  align-items: center;
  align-content: center;
  padding: 12px 8px;
  /*letter-spacing: 1px;*/
  color: #667085;
  font-weight: 200;
  font-size: 15px;
  /*border-bottom: 1px solid #E3EBF6;*/
  text-transform: lowercase;
}

td::first-letter {
  text-transform: capitalize;
}

.empty-state{
  text-align: center;
  margin-top: 7%;
  margin-right: 8%;
}


.empty-state-text-1{
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #353542;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}

.empty-state-text-2{
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #353542;
  padding-bottom: 1.25%;
}

.empty-state-text-3{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;
  width: 130px;
  margin-right: auto;
  margin-left: auto;
  height: 35px;
  background: #0765FF;
  color: #FFFFFF;
  border: 1px solid #0765FF;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 5px;
  font-size: 13px;
}

.empty-state-text-3:hover{
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.pagination{
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}

.previous{
  /*display: flex;*/
  /*align-content: center;*/
  /*align-items: center;*/
  text-align: center;
  padding: 8px 14px;
  gap: 8px;
  font-size: 12px;
  width: 120px;
  height: 32px;
  background: #1570EF;
  color: #ffffff;
  border: 1px solid #1570EF;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
}

.previous:hover{
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.page-indicator{
  color: #667085;
  font-weight: 200;
  font-size: 15px;
}

.bx-log-in-circle{
  color: #0aae43;
  font-size: 23px;
  font-weight: 300;
}

.bx-log-out-circle{
  color: #DB657B;
  font-size: 23px;
  font-weight: 300;
}
</style>