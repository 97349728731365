import client  from "./BaseService";

export default {
    callCreateApi(payload) {
        return client.apiClient.post("wallet/create", payload);
    },
    callBalanceEnquiryApi(payload){
        return client.apiClient.post("wallet/balance-enquiry", payload);
    },
    callRetrieveApi(payload){
        return client.apiClient.post("wallet/retrieve", payload)
    },
    callReadApi(payload){
        return client.apiClient.post("wallet/read", payload)
    },
    callWalletBlockAndUnblockApi(payload){
        return client.apiClient.post("wallet/account-block-and-unblock", payload)
    },
    callAmountBlockAndUnblockApi(payload){
        return client.apiClient.post("wallet/amount-block-and-unblock", payload)
    },
    callReadLienApi(payload){
        return client.apiClient.post("wallet/read-lien",payload)
    },
    callReadWalletActivityByAccountNumberApi(payload){
        return client.apiClient.post("wallet/read-wallet-transaction",payload)
    },
    callReadWalletActivityApi(){
        return client.apiClient.post("wallet/read-all-transaction",{})
    },
};